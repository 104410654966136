import { Typography, Row, Col, Space } from "antd";
import { Container} from "./index.styled";
import { APP_NAME } from "../../../_shared";
const { Text, Title } = Typography;


interface SectionTwoProps {
    text?: string;
}
export const SectionTwo = (props: SectionTwoProps) => {
    const { } = props;
    

    const renderCols = [
        'Decentralized and Privacy centric',
        'Automatic smart-contract deployment',
        'Allows for Time Reward',
        'Allows for Volume Reward',
        'Allows for Special Whitelist Reward',
        'Leaderboard (with option of reward)',
        'Token Vesting and Distribution option',
        'KYC service for project owners',
        'Escrow service to boost investor confidence',
        'Multiple assets can be used as donation currency',
        'Allows donations on multiple chains concurrently',
        'Allows for Referral rewards (does not apply to direct transfers) ',
    ].map((v, key)=>(
        <Col key={key} className="gutter-row" xs={12} lg={8}>
            <div className="gutter-row-container" >
                <Space direction="vertical" align="start" size={8}>
                    <Text className="number-container">{key+1}</Text>
                    <Text>{v}</Text>
                </Space>
            </div>
        </Col>
    ))
    return (<Container id="our-features">
                
                <Row className="ant-row-center app-header-row">
                    <Title level={2}>
                        {APP_NAME} App Features
                    </Title>
                    <Col span={24} className="m-point-text"><Text>We offer a majority of features to make your experience worthwhile</Text></Col>
                </Row>
            
                

                <Row className="features-panel" gutter={[18, 18]}>
                    {renderCols}
                </Row>
            </Container>)
};


