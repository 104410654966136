import { ReactNode, Suspense, useContext } from 'react';
// import './styles/globals.css'
// import './styles/sacrifice.min.css'
// import './styles/override.css'
import LandingPage from './pages/landing';
import MyProjectsPage from './pages/projects';
import NewProjectPage from './pages/projects/new';
import NewProjectSuccessPage from './pages/projects/[id]/new';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Page404 from './pages/404';
import { MainLayoutContainer, PmLayoutContainer } from './container';
import SacPage from './pages/sac/[id]';
import ViewSacrificePage from './pages/projects/[id]/view';
import EditSacrificePage from './pages/projects/[id]/edit';
import { WalletConnectorContext } from './contexts';
import { SignAddressButton } from './components';
import { theme } from 'antd';
import ManageProjectPage from './pages/projects/manage';
import FaucetPage from './pages/faucet';
import StakePage from './pages/stake';
const { useToken } = theme;

const Loading = () => null;

const App = () => {
  const walletContext = useContext(WalletConnectorContext);
  const { token } = useToken();

  const getLayout = (Component: ReactNode, type = 'main') => {
    const renderClassName = (Component as any)?.renderClassName;
    // console.log(renderClassName);
    return type === 'main' ? (
      <MainLayoutContainer
        renderClassName={renderClassName}
        controlled={renderClassName}
      >
        {Component}
      </MainLayoutContainer>
    ) : (
      <PmLayoutContainer>{Component}</PmLayoutContainer>
    );
  };
  return (
    <div data-id={'entry-point'} style={{ background: token.colorBgLayout }}>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <Routes>
            <Route index element={<LandingPage />} />
            {['/projects/:tab?'].map((r) => {
              return walletContext.signatureIsDirty ? (
                <Route
                  index
                  element={
                    <SignAddressButton
                      address={walletContext?.address ?? ''}
                      loading={walletContext.loadingSigning}
                      handleClick={() => {
                        walletContext.signAddress();
                      }}
                    />
                  }
                />
              ) : (
                <Route path={r} key={'0001'}>
                  <Route
                    index
                    element={getLayout(<MyProjectsPage />)}
                    key={'00011'}
                  />
                  <Route
                    path="new"
                    element={getLayout(<NewProjectPage />)}
                    key={'00012'}
                  />
                  <Route path=":id" key={'00013'}>
                    <Route
                      path="success"
                      element={getLayout(<NewProjectSuccessPage />)}
                      key={'000131'}
                    />
                    <Route
                      path="view"
                      element={getLayout(<ViewSacrificePage />)}
                      key={'000132'}
                    />
                    <Route
                      path="edit"
                      element={getLayout(<EditSacrificePage />)}
                      key={'000133'}
                    />
                    <Route
                      path="manage"
                      element={getLayout(<ManageProjectPage />, 'pm')}
                      key={'00031323'}
                    />
                  </Route>
                </Route>
              );
            })}
            <Route path="sac" key={'0002'}>
              <Route
                path=":id"
                element={getLayout(<SacPage />)}
                key={'00021'}
              />
            </Route>
            <Route path="faucet" key={'0003'}>
              <Route
                index
                element={getLayout(<FaucetPage />)}
                key={'00031'}
              />
            </Route>

            <Route path="stake" key={'0003'}>
              <Route
                index
                element={getLayout(<StakePage />)}
                key={'00031'}
              />
            </Route>

            

            <Route
              path="*"
              element={
                walletContext.signatureIsDirty ? (
                  <SignAddressButton
                    address={walletContext?.address ?? ''}
                    loading={walletContext.loadingSigning}
                    handleClick={() => {
                      walletContext.signAddress();
                    }}
                  />
                ) : (
                  getLayout(<Page404 />)
                )
              }
              key={'0003'}
            />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  );
};

export default App;
