import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space, Table, Typography } from "antd";
import {
  resolveCurrentNetworkObject,
  shorternAddress,
} from "../../../../_shared";
import { AdminNamespace } from "../../../../_shared/namespaces/admin";
import { AdminBonusWrapper } from "./index.styled";
import { ColumnsType } from "antd/es/table";
const { Text } = Typography;

interface AdminBonusComponentProps {
  adminBonuses: AdminNamespace.AdminBonus[];
  selectedAdminBonus?: AdminNamespace.AdminBonus;
  loading: boolean;
  handletoggleAdminBonusModal: (data?: any) => void;
  handleManageAdminBonus: (data: any, type: string) => void;
}
export const AdminBonusComponent = (props: AdminBonusComponentProps) => {
  const {
    adminBonuses,
    loading,
    selectedAdminBonus,
    handletoggleAdminBonusModal,
    handleManageAdminBonus,
  } = props;

  const columns: ColumnsType<AdminNamespace.AdminBonus> = [
    {
      title: "Address",
      key: "contributionAmount",
      render: (_: any, data) => {
        return <Text className="number-font" copyable={{ text: data.address }}>{shorternAddress(data.address)}</Text>;
      },
    },
    {
      title: "Points",
      key: "percentage",
      render: (_: any, data) => {
        return <Text className="number-font">{data.points}</Text>;
      },
    },
    {
      title: "Network",
      key: "chainId",
      render: (_: any, data) => {
        return (
          <Text className="">
            {resolveCurrentNetworkObject(`${data.chainId}`)?.label}
          </Text>
        );
      },
    },
    // {
    //   title: "Action",
    //   key: "id",
    //   dataIndex: "network",
    //   render: (_: any, data, key) => {
    //     return (
    //       <Button
    //         shape="round"
    //         loading={loading && selectedAdminBonus?.id === data.id}
    //         onClick={() => {
    //           handleManageAdminBonus(data, "set-selected");
    //           handleManageAdminBonus(data, "remove-selected");
    //         }}
    //         type="text"
    //         icon={<DeleteFilled />}
    //         className="delete-btn"
    //       />
    //     );
    //   },
    // },
  ];
  return (
    <AdminBonusWrapper className="pt-10">
      <div className="hint">
        <Text>Issue points directly to one or more addresses</Text>
      </div>
      <Space className="top-bar mb-10" align="center">
        <Button
          loading={loading && !selectedAdminBonus}
          onClick={() => {
            handleManageAdminBonus(null, "set-selected");
            handletoggleAdminBonusModal();
          }}
          type="primary"
          className="new-group-btn"
          ghost
          icon={<PlusOutlined />}
        >
          New Admin Reward
        </Button>
      </Space>
      <Table
        className="w-full"
        dataSource={adminBonuses}
        columns={columns}
        loading={loading}
        pagination={false}
      />
    </AdminBonusWrapper>
  );
};
