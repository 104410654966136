import {
  CheckCircleFilled,
  GlobalOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Progress,
  Space,
  Table,
  Tabs,
  TabsProps,
  Tag,
  Timeline,
  Typography,
  theme,
} from "antd";
import { ProjectNamespace } from "../../../_shared/namespaces/project";
import { useProject } from "../../../hooks";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { moneyFormat } from "../../../_shared";

const { Text } = Typography;
const { useToken } = theme;


const onChange = (key: string) => {
  console.log(key);
};

interface SecLeadersBoardProps {
  project: ProjectNamespace.Project;
}
export const SecLeadersBoard = (props: SecLeadersBoardProps) => {
  const { token } = useToken();
  const { project } = props;

  const KEY = `@@SecLeadersBoard`;

  const [sacrificeList, setSacrificeList] = useState<
    LeaderBoardNamespace.Data[]
  >([]);
  const [referralList, setReferralList] = useState<
    LeaderBoardNamespace.Data[]
  >([]);

  const { handleGet } = useProject({
    key: KEY,
  });

  useEffect(() => {
    handleGet(`${project.projectAddress}/top-contributors`, {
      onFinish: (data: any) => {
        setSacrificeList(data);
      },
    });
    handleGet(`${project.projectAddress}/top-referrals`, {
      onFinish: (data: any) => {
        setReferralList(data);
      },
    });
  }, []);

  const columns = (type: string): ColumnsType<LeaderBoardNamespace.Data> => [
    {
      title: "#",
      key: "id",
      render: (_: any, data, index) => {
        return <Text className="number-font">{index+1}</Text>;
      },
    },
    {
      title: "Wallet",
      key: "wallet",
      render: (_: any, data) => {
        return (
          <Text className="number-font">{data.walletAddress}</Text>
        );
      },
    },
    {
      title: type=='sac'?"USD Contributed":'Referral Reward Earned',
      key: "usd_sacrificed",
      render: (_: any, data, index) => {
        return (
          <Text className="number-font">
            {type=='sac'? '$':''} {moneyFormat(data.usd_sacrificed)}
          </Text>
        );
      },
    },
  ];

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Contribution Leaderboard`,
      children: (
        <Table
          dataSource={sacrificeList}
          columns={columns('sac')}
          pagination={false}
        />
      ),
    },
    {
      key: "2",
      label: `Referral Leaderboard`,
      children: (
        <Table
          dataSource={referralList}
          columns={columns('referral')}
          pagination={false}
        />
      ),
    },
  ];

  return (
    <div
      className="flex flex-col relative sac-leadersboard px-[40px] py-[33px] "
      style={{
        backgroundColor: token.colorBgContainer,
        border: "1px solid rgba(255, 255, 255, 0.05)",
      }}
    >
      {/* layout 1 */}
      <div className="flex mb-[30px] justify-start">
        <Text className="text-lg font-semibold">Leaderboard</Text>
      </div>

      {/* layout 2 */}
      <div className="flex  ">
        <Tabs
          className="w-full"
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
