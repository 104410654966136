import { CopyFilled } from "@ant-design/icons";
import {  Form, Input, Space, Typography } from "antd";
import { ModalWrapper } from "../index.styled";
import { ShareWrapper } from "./index.styled";
const {Text} = Typography;

interface AddTokenModalProps{
    visible: boolean
    onClose?: () => void
    onCopied?: () => void
    text?: string
    subHeading?: string
}
export const ShareModal = (props: AddTokenModalProps) => {
    const {visible, onCopied, onClose, text, subHeading} = props;
    
    return (
          <ModalWrapper  
                title={
                    <Space direction="vertical" size={0}>
                        <Text className="m-title">Share</Text>
                        <Text style={{
                            fontWeight: 400,
                            fontSize: 14,
                            color: 'var(--disabled-color)'
                        }}>{subHeading??"Click to copy URL"}</Text>
                    </Space>
                } 
                footer={null}
                visible={visible}
                onCancel={onClose}
                >
                    <ShareWrapper onClick={onCopied}>
                        <Form.Item
                        >
                            <Input value={text??"https://solidbase.xyz"}  disabled={true} suffix={<CopyFilled  />} />
                        </Form.Item>
                    </ShareWrapper>
                    
            
          </ModalWrapper>
    );
};