import { CaretDownOutlined, DownOutlined} from "@ant-design/icons";
import { Button, Col, Divider, Dropdown, Form, Input, Menu, Row, Select, Space, Spin, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { toEther } from "../../../../web3/libs/utilities";
import { cleanInput, moneyFormat,  NETWORKS_TYPE, resolveNetworkObject, SELECT_A_TOKEN, TOKEN_SYMBOL } from "../../../../_shared";
import { ProjectNamespace } from "../../../../_shared/namespaces/project";
import { AcceptedToken, GetBonusStat, UserDataStat } from "../../../../_shared/utils/type";
import { CoverContainer } from "./index.styled";
const { Text } = Typography;
const { Option } = Select;

interface CurrentRatePointProps{
  project?: ProjectNamespace.Project;
  amountError?: string;
  referralError?: string;
  sacData: {
    amount?: string;
    uplineAddress?: string;
    tokenAddress?: string;
    dollarValue?: string;
    calcPoints?: number;
  };
  address: string;
  pointRate?: string
  currentNetwork?: NETWORKS_TYPE
  handleChange: (address: string,amount: string, referalAddress?:string, delay?: number)=>void
  handleOnFinish: (data?:any)=>void
  handleSwitchNetwork: (data?:any)=>void
  
  acceptedTokens?: AcceptedToken[]
  // donationPoints?: string
  bonusData?: GetBonusStat
  uiLoaders: Record<any, boolean>
  userDataStats?: UserDataStat
  deployToggle: boolean;
  loading: boolean;
  loadingNetworkSwitch: boolean;
  tokenBalance?: string;
  usdDecimals?: number;
}

export const CurrentRatePoint = (props: CurrentRatePointProps) => {
  const {handleChange, amountError, referralError,
          pointRate, acceptedTokens, bonusData,uiLoaders,
          loading: loadingBonuses, project,loadingNetworkSwitch,
    currentNetwork, userDataStats, sacData,
    address,
        handleOnFinish, handleSwitchNetwork, usdDecimals, tokenBalance} = props;

  const loadingSacrificingProcess = uiLoaders['approve'] || uiLoaders['allowance'] || uiLoaders['sacrificeWithBonus']
  const loading = uiLoaders['dollarValue'] || uiLoaders['calculatePointToken'];
  const disableSacrificeButton  =  !_.isEmpty(amountError) || loadingBonuses || loading || !(sacData.calcPoints);
  const [form] = useForm();
  const [referallForm] = useForm();
  const donationPoints = sacData.calcPoints;
  const [dollarVal, setDollarVal] = useState('');

  const [selectedToken, setSelectedToken] = useState(SELECT_A_TOKEN);
  let oldDollarVal = '';
  useEffect(() => {
    if (loadingBonuses || loading || !sacData.dollarValue) {
      oldDollarVal = String(dollarVal);
      setDollarVal('');
    }
    else {
      if(oldDollarVal !== sacData.dollarValue) {
      setDollarVal(sacData.dollarValue);
      }
    }

  }, [sacData.dollarValue, loadingBonuses]);
  useEffect(() => {
    form.setFieldsValue({
      referalAddress: userDataStats?.uplineAddress??''
    })
  }, [userDataStats]);

  useEffect(() => {
    setSelectedToken(SELECT_A_TOKEN)
  }, [currentNetwork])

  const onChange = (data:any)=>{
    setSelectedToken(data)
    handleChange(data, form.getFieldValue("amount"), form.getFieldValue("referalAddress"));
    // console.log('data', data, form.getFieldValue("amount") )
  }
  const clearForm = () => {
    form.setFieldsValue({amount: ''});
  }
  
  const handleInputChange = useCallback((_:any)=>{
    const clean = cleanInput(form.getFieldValue('amount'));
    if(form.getFieldValue('amount') !== clean) {
      form.setFieldsValue({amount: clean});
      return;
    }
    handleChange(selectedToken, form.getFieldValue("amount"),  form.getFieldValue("referalAddress"), 1000);
  }, [form, selectedToken])

  const handleInputReferrerChange = useCallback((_:any)=>{
    handleChange(selectedToken, form.getFieldValue("amount"), form.getFieldValue("referalAddress"), 1000);
  }, [form, referallForm, selectedToken])
  const tokenList = (acceptedTokens??[]).reduce((prev, token, index)=>(
      prev+`${token.symbol===''?currentNetwork?.nativeToken:token.symbol}${(acceptedTokens??[]).length-1!==index?', ':''}`
    ), "")
  const selectAfter = (
    <Select 
          // disabled={loading}
          onChange={onChange}
          suffixIcon={<CaretDownOutlined />} 
          defaultValue={SELECT_A_TOKEN} 
          value={selectedToken}
          className="select-after ant-btn-primary">
       {(acceptedTokens??[]).map((token, index)=>(
                                <Option value={token.token} key={index}>{token.symbol===''?currentNetwork?.nativeToken:token.symbol}</Option>
                            ))}
    </Select>
  );
  const resolvedNetworks: NETWORKS_TYPE[] =  resolveNetworkObject(project?.networks??[])
  const networkTokensMenu = (
    
    <Menu
      items={resolvedNetworks.map((network, key)=>{
        return ({
          label: <Text>{network.label}</Text>,
          key,
          onClick: ()=>handleSwitchNetwork(network)
        })
      })
        
      }
    />
    
  );
  

  const __total = Number(donationPoints) +
                   Number(bonusData?.volumeBonus??0) + 
                   Number(bonusData?.whitelistBonus??0)+
                   Number(bonusData?.refereeBonus??0)+
                   Number(bonusData?.psacBonus??0);

                
  return (
        <CoverContainer id="summary">
            <Row justify="space-between">
              <Col>
                
              </Col>
              <Col>
                  <Dropdown disabled={loadingNetworkSwitch} overlay={networkTokensMenu} trigger={['click']}>
                    <a onClick={e => e.preventDefault()}>
                      <Space>
                        Choose Network: 
                        <div style={{border: '1px solid #ffffff3f', borderRadius: 5, paddingLeft: 10, paddingRight: 10,  paddingTop: 6, paddingBottom: 6}}>
                        <Space size={10}><Text className="title-text-network">{currentNetwork?.label}</Text>
                        {loadingNetworkSwitch?
                          <Spin/>:
                          <DownOutlined />}
                          </Space>
                          </div>
                      </Space>
                      
                    </a>
                  </Dropdown>
                
                
                  
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>
                <Text>Current Point Rate</Text>
              </Col>
              <Col>
                <Text className="number-font title-text">{moneyFormat(Number(pointRate), 0)} points/USD</Text>
              </Col>
            </Row>
            
            <Form              
              layout="vertical"
              form={form}
            >

              <Form.Item 
                style={{marginBottom: '0px'}}
                label={<Row style={{width:'100%'}}  justify="space-between"><Col>Amount</Col>
                {!_.isEmpty(tokenBalance) && <Col className="justify-center align-items-center" style={{color: 'grey'}}>Bal: {moneyFormat(toEther(String(tokenBalance)), 2)}</Col>}</Row>}
                name="amount"
               
                extra={
                  <>{amountError&&<Text style={{fontSize: '11px'}} className="error-text">{amountError}</Text>}
                  {!_.isEmpty(dollarVal)&&!loading&&!amountError&&dollarVal!=='0'&&<Text  style={{color: 'grey'}} className="">~ ${moneyFormat(toEther(dollarVal, 2, usdDecimals),2)}</Text>}
                  </>
                }
                >
                <Input autoComplete="off" disabled={loading} onChange={handleInputChange} placeholder="e.g 10000"  addonAfter={selectAfter} />
                
              </Form.Item>

              <Form.Item 
                label="Referral Address"
                name={'referalAddress'}
                rules={[{ message: 'Please input address!' }]}
                extra={
                  <>{referralError &&<Text style={{fontSize: '11px'}} className="error-text">{referralError}</Text>}
                 
                  </>
                }
                
                >
                <Input onChange={handleInputReferrerChange} disabled={loadingSacrificingProcess || !(_.isEmpty(userDataStats?.uplineAddress))} placeholder="Enter Referral Address" />
              </Form.Item>
              
              
          

              <Row justify="space-between" className="__point_">
                <Col>
                  <Text className="muted">Direct Points</Text>
                </Col>
                {_.isEmpty(amountError) && <Col>
                {loadingBonuses ? <Spin size="small"/> : <Text className="muted">{moneyFormat(Number(sacData.calcPoints))}</Text>}
                </Col>}
                {!_.isEmpty(amountError) && <Col>
                0
                </Col>}
              </Row>
              <Row justify="space-between" className="__point_">
                <Col>
                  <Text className="muted">Volume Reward</Text>
                </Col>
                {_.isEmpty(amountError) && <Col>
                {loadingBonuses ? <Spin size="small"/> : <Text className="muted">{moneyFormat(Number(bonusData?.volumeBonus??0))}</Text>}
                </Col>}
                {!_.isEmpty(amountError) && <Col>
                0
                </Col>}
              </Row>
              <Row justify="space-between" className="__point_">
                <Col>
                  <Text className="muted">Whitelist Reward</Text>
                </Col>
                {_.isEmpty(amountError) && <Col>
                {loadingBonuses ? <Spin size="small"/> : <Text className="muted">{moneyFormat(Number(bonusData?.whitelistBonus??0))}</Text>}
                </Col>}
                {!_.isEmpty(amountError) && <Col>
                0
                </Col>}
              </Row>

              <Row justify="space-between" className="__point_">
                <Col>
                  <Text className="muted">Referee Bonus</Text>
                </Col>
                {_.isEmpty(amountError) && <Col>
                {loadingBonuses ? <Spin size="small"/> : <Text className="muted">{moneyFormat(Number(bonusData?.refereeBonus??0))}</Text>}
                </Col>}
                {!_.isEmpty(amountError) && <Col>
                0
                </Col>}
              </Row>
              <Row justify="space-between" className="__point_">
                <Col>
                  <Text className="muted">{TOKEN_SYMBOL} Community Bonus</Text>
                </Col>
                {_.isEmpty(amountError) && <Col>
                {loadingBonuses ? <Spin size="small"/> :<Text className="muted">{moneyFormat(Number(bonusData?.psacBonus??0))}</Text>}
                </Col>}
                {!_.isEmpty(amountError) && <Col>
                0
                </Col>}
              </Row>
              <Row justify="space-between" className="__point_">
                <Col>
                  <Text className="title-text">Total</Text>
                </Col>
                {_.isEmpty(amountError) && <Col>
                  {loadingBonuses ? <Spin size="small"/> : <Text className="title-text">{moneyFormat(__total)}</Text>}
                </Col>}
                {!_.isEmpty(amountError) && <Col>
                0
                </Col>}
              </Row>

              
              <Form.Item style={{marginTop: '24px'}}>
                <Row>
                <Button size="large" onClick={()=>handleOnFinish(clearForm)} disabled={disableSacrificeButton} loading={loadingSacrificingProcess} type="primary" className="bg-success" shape="round" htmlType="submit" style={{marginLeft:"auto"}}>
              {address ? 'Contribute' : 'Connect Wallet To Contribute'}
                </Button>
                </Row>
              </Form.Item>
              
            </Form>

            <Divider />

            <Row justify="center" gutter={[2, 16]} align="middle" style={{flexDirection:'column'}}>
              <Col>
                <Text  className="disabled">Accepted Tokens</Text>
              </Col>
              <Col>
                <Text className="title-text title-text-1">{tokenList}</Text>
              </Col>
            </Row>
            {loading && <span className="spin-wrapper"><Spin size="large"/></span>}
        </CoverContainer>
  );
};


