import { Button, message, notification, Spin } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { WalletConnectorContext } from "../../contexts";

import { Container } from "./index.styled";
import Web3 from "web3";
import { sacAdminAbi } from "../../_shared/ABIcode/sac-admin";
import {
  EMPTY_ADDRESS,
  PSAC_TOKEN_ADDRESS,
  SAC_ADMIN_ADDRESS,
  sacToken,
  STAKE_CONTRACT,
} from "../../_shared";
import { size } from "lodash";
import { StakeComponent } from "../../components/stake-project";
import { useProject, useUIState } from "../../hooks";
import { ProjectNamespace } from "../../_shared/namespaces/project";
import { stakeContractAbi } from "../../_shared/ABIcode/stack-contract";

export const StakeContainer = () => {
  const { uiLoaders } = useUIState();
  const { address, provider, loadingSigning } =
    useContext(WalletConnectorContext);

  const [loadTableData, setLoadTableData] =
    useState<ProjectNamespace.TableData>();
  const [penaltyFee, setPenaltyFee] = useState("");
  const KEY = `@@stake`;
  const loading = uiLoaders[KEY];
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUnstake, setIsLoadingUnstake] = useState(false);
  const { handleGet } = useProject({
    key: KEY,
  });

  useEffect(() => {
    if (!address) return;
  }, [address]);

  useEffect(() => {
    if (!address) return;
    loadTData();
    loadWeb3Data();
  }, [address]);

  const loadWeb3Data = useCallback(async () => {
    let fee = await getPenalty();
    setPenaltyFee(fee);
  }, [address]);

  const loadTData = useCallback(() => {
    handleGet(`stakeData/${address ?? EMPTY_ADDRESS}`, {
      onFinish: (data: any) => {
        setLoadTableData(data);
      },
    });
  }, [address, handleGet]);
  const getToken: () => Promise<boolean> = async () => {
    const web3 = new Web3(provider);

    const contract = new web3.eth.Contract(
      sacAdminAbi as any,
      SAC_ADMIN_ADDRESS
    );

    try {
      const getToken: any = await contract.methods["getToken"](
        PSAC_TOKEN_ADDRESS
      ).send({
        from: address,
      });
      console.log({ getToken });
      notification.success({
        message: "Sent Successfully",
      });
      return true;
    } catch (error) {
      console.log({ error });
      return false;
    }
  };

  const getPenalty: () => Promise<string> = async () => {
    const web3 = new Web3(provider);

    const stakeContract = new web3.eth.Contract(
      stakeContractAbi as any,
      STAKE_CONTRACT
    );

    try {
      const amountInWei: any = await stakeContract.methods[
        "calcPenaltyForUser"
      ](address, Math.floor(Date.now() / 1000)).call();
      console.log({ rsp: amountInWei });
      return amountInWei;
    } catch (error) {
      console.log({ error });
      return null;
    }
  };

  const handleOnFinishAddDuration: (duration: any) => Promise<boolean> = async ({duration}) => {

    setIsLoading(true);
    const web3 = new Web3(provider);

    const stakeContract = new web3.eth.Contract(
      stakeContractAbi as any,
      STAKE_CONTRACT
    );
    try {
      console.log({duration})
      const increaseDuration: any = await stakeContract.methods["increaseDuration"](
        duration
      ).send({
        from: address,
      });
      console.log({ increaseDuration });
      notification.success({
        message: "Duration Increased Successfully",
      });
      setTimeout(() => {
        loadTData();
        loadWeb3Data();
      }, 3000);
      setIsLoading(false);
      return true;
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
      return false;
      
    }
  };

  const handleUnstake: () => Promise<boolean> = async () => {

    setIsLoadingUnstake(true);
    const web3 = new Web3(provider);

    const stakeContract = new web3.eth.Contract(
      stakeContractAbi as any,
      STAKE_CONTRACT
    );
    // console.log({www: loadTableData.levels[Number(loadTableData.user.level)].duration})
    try {
      const unstake: any = await stakeContract.methods["unstake"](
        // loadTableData.levels[Number(loadTableData.user.level)].duration
      ).send({
        from: address,
      });
      console.log({ unstake });
      notification.success({
        message: "Unstaked Successfully",
      });
      setTimeout(() => {
        loadTData();
        loadWeb3Data();
      }, 3000);
      setIsLoadingUnstake(false);
      return true;
    } catch (error) {
      console.log({ error });
      setIsLoadingUnstake(false);
      return false;
      
    }
  };

  const handleOnFinishStakeMore = async (data: any) => {
    
    setIsLoading(true);
    
    const amountIntWei = Web3.utils.toWei(data.amount+"").toString();
    console.log({data,amountIntWei})
    try {
      const chAllow = await checkContractAllowanceEnough(amountIntWei);
      console.log({ chAllow });
      if (!chAllow) {
        const upAllow = await updateAllowance(amountIntWei);
        console.log({ upAllow });
      }
      const stakeResp = await stakeMoreContract(amountIntWei);
      setTimeout(() => {
        loadTData();
        loadWeb3Data();
      }, 3000);
      notification.success({
        message: "Staked Increased Successfully",
      });
      console.log({ stakeResp });
    } catch (error) {
      console.log({ hanldeUnlockErr: error });
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleOnFinish = async (data: any) => {
    
    setIsLoading(true);
    
    const amountIntWei = Web3.utils.toWei(data.amount+"").toString();
    console.log({data,amountIntWei})
    try {
      const chAllow = await checkContractAllowanceEnough(amountIntWei);
      console.log({ chAllow });
      if (!chAllow) {
        const upAllow = await updateAllowance(amountIntWei);
        console.log({ upAllow });
      }
      const stakeResp = await stakeContract(amountIntWei, data.duration);
      setTimeout(() => {
        loadTData();
        loadWeb3Data();
      }, 3000);
      notification.success({
        message: "Staked Successfully",
      });
      console.log({ stakeResp });
    } catch (error) {
      console.log({ hanldeUnlockErr: error });
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const checkContractAllowanceEnough: (fee:string) => Promise<boolean> = async (fee) => {
    const web3 = new Web3(provider);

    const psacContract = new web3.eth.Contract(
      sacToken as any,
      PSAC_TOKEN_ADDRESS
    );

    try {
      const amountInWei: any = await psacContract.methods["allowance"](
        address,
        STAKE_CONTRACT
      ).call();
      console.log({ amountInWei });
      const _n = BigInt(`${fee}`);
      return _n < amountInWei;
    } catch (error) {
      console.log({ error });
      return false;
    }
  };

  const updateAllowance: (fee:string) => Promise<boolean> = async (fee) => {
    
    console.log({ SAC_ADMIN_ADDRESS, address });

    const web3 = new Web3(provider);

    const psacContract = new web3.eth.Contract(
      sacToken as any,
      PSAC_TOKEN_ADDRESS
    );

    // allowance // call
    try {
      const updateAllowance: any = await psacContract.methods["approve"](
        STAKE_CONTRACT,
        fee
      ).send({
        from: address,
      });
      console.log({ updateAllowance });
      return true;
    } catch (error) {
      return false;
    }
  };

  const stakeContract: (
    amount: string,
    durationNumber: string
  ) => Promise<string> = async (amount: string, durationNumber: string) => {
    const web3 = new Web3(provider);

    const stakeContract = new web3.eth.Contract(
      stakeContractAbi as any,
      STAKE_CONTRACT
    );
    

    try {
      const amountInWei: any = await stakeContract.methods["stake"](
        amount,
        durationNumber
      ).send({
        from: address,
      });
      console.log({ rsp: amountInWei });
      
      return amountInWei;
    } catch (error) {
      console.log({ error });
      return null;
    }
  };

  const stakeMoreContract: (
    amount: string,
  ) => Promise<string> = async (amount: string,) => {
    const web3 = new Web3(provider);

    const stakeContract = new web3.eth.Contract(
      stakeContractAbi as any,
      STAKE_CONTRACT
    );
    

    try {
      const amountInWei: any = await stakeContract.methods["increaseVolume"](
        amount,
      ).send({
        from: address,
      });
      console.log({ rsp: amountInWei });
      
      return amountInWei;
    } catch (error) {
      console.log({ error });
      return null;
    }
  };

  const increaseStakeDuration: (
    
    durationNumber: string
  ) => Promise<string> = async ( durationNumber: string) => {
    const web3 = new Web3(provider);

    const stakeContract = new web3.eth.Contract(
      stakeContractAbi as any,
      STAKE_CONTRACT
    );
    

    try {
      const amountInWei: any = await stakeContract.methods["durationInDays"](
        durationNumber
      ).send({
        from: address,
      });
      console.log({ rsp: amountInWei });
      
      return amountInWei;
    } catch (error) {
      console.log({ error });
      return null;
    }
  };

  return (
    <Container>
      <StakeComponent
        penaltyFee={penaltyFee}
        loading={loading}
        loadingContract={isLoading}
        isLoadingUnstake={isLoadingUnstake}
        tableData={loadTableData}
        onFinish={handleOnFinish}
        handleUnstake={handleUnstake}
        onFinishStakeMore={handleOnFinishStakeMore}
        onFinishAddDuration={handleOnFinishAddDuration}
      />
    </Container>
  );
};
