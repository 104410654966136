import {Button, Carousel, Tag, theme, Typography} from "antd";
// import Link from "next/link";
import {Container} from "./index.styled";
import {ProjectNamespace} from "../../../_shared/namespaces/project";
import {useNavigate} from "react-router-dom";

const { useToken } = theme;
const { Text } = Typography;

interface CarouselSectionProps {
  projects: ProjectNamespace.Project[];
}

export const CarouselSection = (props: CarouselSectionProps) => {
  const { projects } = props;

  const { token } = useToken();
  const navigate = useNavigate();
  const contentStyle: React.CSSProperties = {
    height: "100px",
    color: "#fff",
    // lineHeight: "160px",
    textAlign: "center",
    background: token.colorBorderSecondary,
  };

  return (
    <Container>
      <Carousel autoplay>
        {/* <div>
          <div className={`card `}>
            <div className="flex w-full h-full">
              <div
                style={{ backgroundColor: "#2D273F" }}
                className="flex flex-col w-[40%] justify-center items-start p-[40px] rounded-l-[20px] gap-[40px]"
              >
                <Tag color="red-inverse" className="spotlight border-none">
                  SPOTLIGHT
                </Tag>
                <Text className="text-4xl">
                   Solidbase - first crowdfunding as a service dapp on pulsechain
                </Text>
                <Button type="primary" className="shadow-none">
                  Join Fundraiser
                </Button>
              </div>
              <div className="flex _fill w-[60%] rounded-r-[20px]">
                <img src={"/lotto.png"} alt="avatar" />
              </div>
            </div>
          </div>
        </div> */}
        {projects.map((project) => (
          <div>
            <div className={`card `}>
              <div className="flex w-full h-full ">
                <div
                  style={{
                    backgroundColor: "#2D273F",
                    backgroundImage: `url('${project?.spotlightImage}')`,
                  }}
                  className="flex flex-col w-full md:w-[40%] justify-center items-start px-[40px] rounded-[20px] md:rounded-r-none gap-[40px] info-tab bg-cover bg-no-repeat md:!bg-[url('')] bg-[url('https://i.imgur.com/0qRwdSI.png')]"
                >
                  <Tag color="red-inverse" className="spotlight border-none">
                    SPOTLIGHT
                  </Tag>
                  <Text className="text-4xl">
                    {project.title} - {project.tagline}
                  </Text>
                  <Button
                    type="primary"
                    onClick={() => {
                      navigate(`/sac/${project.id}`);
                    }}
                    className="shadow-none"
                  >
                    Join Fundraiser
                  </Button>
                </div>
                <div className="md:flex hidden _fill w-[60%] rounded-r-[20px]">
                  <img
                    src={project.spotlightImage ?? "/lotto.png"}
                    alt="avatar"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}

        {/* <div>
          <div
            style={{ backgroundColor: token.colorPrimary }}
            className={`card`}
          >
            <h3 style={contentStyle}>2</h3>
          </div>
        </div> */}

        {/* <div className="card">
          <h3 style={contentStyle}>3</h3>
        </div>
        <div className="card">
          <h3 style={contentStyle}>4</h3>
        </div> */}
      </Carousel>
    </Container>
  );
};
