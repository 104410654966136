import { Button, Form, Input, InputNumber } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { ModalWrapper } from "../index.styled";
import { FormWrapper } from "./index.styled";

interface NewVolumeBonusModalProps{
    visible: boolean
    loading:boolean
    onFinish?: ((values: any) => void) | undefined
    onClose?: () => void
    selectedVolumeBonus?: {contributionAmount: string; points:string;}
}
export const NewVolumeBonusModal = (props: NewVolumeBonusModalProps) => {
    const {visible, onFinish, onClose, loading, selectedVolumeBonus} = props;
    const [form] = useForm()
    useEffect(() => {
        form.resetFields();
    });
    useEffect(() => {
        form.setFieldsValue(selectedVolumeBonus);
    }, [selectedVolumeBonus]);
    return (
          <ModalWrapper  
                title={`${selectedVolumeBonus?'Edit':'Add'} Volume Reward`} 
                footer={null}
                visible={visible}
                onCancel={onClose}
                >
            
            <FormWrapper
                name="new_volume_bonus"
                layout="vertical"
                initialValues={selectedVolumeBonus}
                onFinish={onFinish}
                form={form}
                autoComplete="off"
                requiredMark={'optional'}
                >
                <Form.Item
                    label="Contribution Amount (USD)"
                    name="contributionAmount"
                    
                    rules={[{ required: true, message: 'Please input amount!' }]}
                >
                    <InputNumber placeholder="Enter Amount" />
                </Form.Item>
                <Form.Item
                    label="Bonus Percentage"
                    name="percentage"
                    
                    rules={[{ required: true, message: 'Please input bonus percentage!' }]}
                >
                    <Input type={'number'} placeholder="e.g 10"  suffix={'%'}/>
                </Form.Item>
                <Form.Item>
                    <Button loading={loading} shape="round" className="submit-btn" type="primary" htmlType="submit">
                    {`${selectedVolumeBonus?'Update':'Save'} Bonus`} 
                    </Button>
                </Form.Item>
                </FormWrapper>
          </ModalWrapper>
    );
};