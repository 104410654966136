import { Button, Grid, message, Space, Typography } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  CameraOutlined,
  CheckOutlined,
  CopyOutlined,
  MoreOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";

import { ProjectSideBar } from "../../../components/project/manage-project/sidebar";
import { Link, useNavigate } from "react-router-dom";
import { ProjectNamespace } from "../../../_shared/namespaces/project";
import {
  copyToClipboard,
  ManageProjectForms,
  moneyFormat,
  NETWORKS_TYPE,
  REACT_APP_BASEURL,
  TOKEN_SYMBOL,
} from "../../../_shared";
import { WhitelistNamespace } from "../../../_shared/namespaces/whitelist";
import { AdminNamespace } from "../../../_shared/namespaces/admin";
import { AcceptedToken } from "../../../_shared/utils/type";
import { WalletConnectorContext } from "../../../contexts";
import { toEther } from "../../../web3/libs/utilities";
import { ManageNetworkComponent } from "../../view-project/libs/manage-network";
import { ManageTokenLoadedComponent } from "../../view-project/libs/manage-token-loaded";
import { WhitelistComponent } from "../../view-project/libs/whitelist";
import { VolumeBonusComponent } from "../../view-project/libs/volume-bonus";
import { AdminBonusComponent } from "../../view-project/libs/admin-bonus";
import { PSacBonusComponent } from "../../view-project/libs/psac-bonus";
import { DistributeTokenComponent } from "../../view-project/libs/distribute-token";
import { AddTokenModal } from "../../view-project/libs/modal/add-token";
import { ShareModal } from "../../view-project/libs/modal/share";
import { NewGroupModal } from "../../view-project/libs/modal/new-group";
import { ManageWhitelistModal } from "../../view-project/libs/modal/manage-whitelist";
import { NewVolumeBonusModal } from "../../view-project/libs/modal/new-volume-bonus";
import { NewAdminBonusModal } from "../../view-project/libs/modal/new-admin-bonus";
import { ClaimPercentageModal } from "../../view-project/libs/modal/claim-percentage";
import { useProject } from "../../../hooks";
import { ActionOption } from "../../../redux/types";
import {
  ExtraFeatureModal,
  UnlockExtraFeatureButton,
} from "../../utils/extra-features/extra-features-modal";
import { ReferralBonusComponent } from "../../view-project/libs/referral-bonus";

require("react/package.json");
var CopyToClipboard = require("react-copy-to-clipboard");

const { useBreakpoint } = Grid;

interface LoadingState {
  loading: boolean;
  loadingDeploy: boolean;
  loadingAddress: boolean;
  loadingWhitelist: boolean;
  loadingAddToken: boolean;
  uiLoaders: Record<string, boolean>;
}

interface FunctionsState {
  handleGet?: (id: string, options?: ActionOption | undefined) => void;
  handleBackArrow: (values?: any) => void;
  handleNetworkSelect: (data?: any) => void;
  handleSelectAvailableNetwork: (any?: any) => void;
  handleDeploy: (data?: any) => void;
  handleNewGroupSubmit: (data: any) => void;
  handleManageGroupSubmit: (data: any) => void;
  handleManageVolumeBonus: (data: any, type: string) => void;
  handleManageAdminBonus: (data: any, type: string) => void;
  handleNewTokenSubmit: (data: any) => void;
  handleRemoveTokenSubmit: (data: any) => void;
  handleWidthdrawToken: (data: any) => void;
  handleNewVolumeBonus: (data: any) => void;
  initWhitelistComponent: () => void;
  setSelectedGroup: (data: any) => void;
  handleShowTokenScreen: (data?: any) => void;
  handleUpdatePsac: (data?: any) => void;
  handleUpdate: (data?: any) => void;
  loadUtils: (id: string) => void;
}
interface DataState {
  project: ProjectNamespace.Project;

  currentNetwork?: NETWORKS_TYPE;
  switchingNetwork?: NETWORKS_TYPE;

  projectInfo?: any;
  projectOwners?: string;
  fees?: {
    currentFeeFlat: string;
    currentFeePercentx100: string;
  };
  allWhitelists: WhitelistNamespace.Whitelist[];
  selectedGroup?: WhitelistNamespace.Whitelist;
  selectedAdminBonus?: AdminNamespace.AdminBonus;
  selectedVolumeBonus?: { contributionAmount: string; points: string };
  acceptedTokens?: AcceptedToken[];
  showTokenScreen: boolean;
  selectedTokenAddress?: string;
  adminBonuses: AdminNamespace.AdminBonus[];
  usdDecimals: string;
  isOwner: boolean;
  projectUtils?: ProjectNamespace.UtilsData;
}
interface ViewProjectComponentProps {
  fns: FunctionsState;
  loadings: LoadingState;
  dataStates: DataState;
  form: string;
}
const ManageProject = (props: ViewProjectComponentProps) => {
  const { dataStates, loadings, fns, form } = props;
  const {
    address,
    loading: loadingConnect,
  } = useContext(WalletConnectorContext);
  const [showFeatureModal, setShowFeatureModal] = useState(false);
  const {
    project: activeProject,
    allWhitelists,
    currentNetwork,
    selectedGroup,
    selectedVolumeBonus,
    projectOwners,
    projectInfo,
    acceptedTokens,
    showTokenScreen,
    selectedTokenAddress,
    adminBonuses,
    selectedAdminBonus,
    fees,
    usdDecimals,
    switchingNetwork,
    projectUtils,
  } = dataStates;
  const {
    loadingDeploy,
    loadingWhitelist,
    loadingAddToken,
    loading,
    uiLoaders,
  } = loadings;
  const {
    handleGet,
    setSelectedGroup,
    handleRemoveTokenSubmit,
    handleWidthdrawToken,
    handleNewVolumeBonus,
    handleNetworkSelect,
    handleSelectAvailableNetwork,
    handleDeploy,
    initWhitelistComponent,
    handleNewGroupSubmit,
    handleManageGroupSubmit,
    handleManageVolumeBonus,
    handleManageAdminBonus,
    handleShowTokenScreen,
    handleUpdatePsac,
    handleNewTokenSubmit,
    handleBackArrow,
    handleUpdate: handleUpdateProject,
  } = fns;
  let navigate = useNavigate();
  const [distributedTokenLoaded, setDistributedTokenLoaded] = useState(false);
  const [showAddTokenModal, setShowAddTokenModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showNewGroup, setShowNewGroup] = useState(false);
  const [showManageGroup, setShowManageGroup] = useState(false);
  const [showNewVolumeBalance, setShowNewVolumeBalance] = useState(false);
  const [showNewAdminBalance, setShowNewAdminBalance] = useState(false);
  const [showDistributionToken, setShowDistributionToken] = useState(false);

  const [loadingBannerImage, setLoadingBannerImage] = useState(false);
  const [project, setProject] =
    useState<ProjectNamespace.Project>(activeProject);

  const [selectedForm, setSelectedForm] = useState(form);
  const [activeTab, setActiveTab] = useState<any>({ manage: "bg-white/5" });

  const { Text } = Typography;

  const handleAddNewToken = () => {
    setShowAddTokenModal((old) => !old);
  };
  const handleNewGroup = () => {
    setShowNewGroup((old) => !old);
  };
  const handleManageGroup = (data: any) => {
    setSelectedGroup({ ...data });
  };
  useEffect(() => {
    if (selectedGroup) {
      if ((selectedGroup as any).action) {
        handleManageGroupSubmit(selectedGroup);
        return;
      }
      setShowManageGroup((old) => !old);
    }
  }, [selectedGroup]);
  useEffect(() => {
    setSelectedForm(form);
  }, [form]);

  useEffect(() => {
    setActiveTab((d: any) => {
      return { [selectedForm]: "bg-white/5" };
    });
    console.log("FORMRMM", activeTab, form);
  }, [selectedForm]);

  const _handleNewVolumeBonusToggle = (data: any) => {
    setShowNewVolumeBalance((old) => !old);
  };
  useEffect(() => {
    if (
      // (!loading && !address && project?.user) ||
      address &&
      project.user &&
      `${address}`.toLowerCase() !== project.user
    ) {
      navigate("/projects");
    }
  }, [address, project.user, loadingConnect]);

  useEffect(() => {
    setProject(activeProject);
  }, [activeProject]);

  const handleStartDistribution = () => {
    setDistributedTokenLoaded((old) => !old);
  };

  const handleClaimPercentage = () => {
    setShowDistributionToken((old) => !old);
  };

  const handleShareButton = () => {
    setShowShareModal((old) => !old);
  };
  const onClose = () => {
    setShowAddTokenModal(false);
    setShowShareModal(false);
    setShowNewGroup(false);
    setShowManageGroup(false);
    setShowNewVolumeBalance(false);
    setShowNewAdminBalance(false);
    setShowDistributionToken(false);
  };

  const onFinish = (data: any) => {
    handleNewTokenSubmit(data);
    setShowAddTokenModal(false);
  };
  const onFinishNewGroup = (data: any) => {
    handleNewGroupSubmit(data);
    setShowNewGroup(false);
  };
  let totalSacrificedValue = "0";
  try {
    totalSacrificedValue = moneyFormat(
      toEther(project.totalSacrificedValue ?? "0", 4)
    );
  } catch (error) {}

  const [listData, _] = useState([
    {
      name: "USDT Sacrificed",
      price: "2,230 USDT",
      bonus: "5%",
    },
    {
      name: "ETH Sacrificed",
      price: "2.3423 ETH",
      bonus: "15%",
    },
    {
      name: "USDC Sacrificed",
      price: "0 USDC",
      bonus: "70%",
    },
  ]);
  const handleOnActivateFeature = () => {
    setShowFeatureModal(false);
    console.log("ACTIVATED");
    fns.loadUtils(String(project.id));
    handleGet?.(`${project.id}/signature`, {
      onFinish: (d) => {},
    });
  };
  const networks = project.networks ?? [];
  const [copied, setCopied] = useState(false);
  const { handleUpdate } = useProject({
    key: "@@BannerUpdate",
  });

  const copyHandler = () => {
    setCopied(true);
    const timeout = setTimeout(() => setCopied(false), 1000);
    return () => clearTimeout(timeout);
  };
  const inputElement = useRef(null);

  const handleOponProject = () => {
    // navigate(`/sac/${project.id}`);
    window.open(`/sac/${project.id}`, "_blank");
  };

  
  return (
    <div className="w-full lg:flex font-antipasto-pro">
      {/* Left Bar */}
      <ProjectSideBar project={project} />
      {/* Cover Photo */}
      <div className="flex-1 2xl:mr-[200px]">
        <div className="px-5 lg:px-0">
          <div className="">
            <div
              style={{
                backgroundImage: `url('${project?.banner}')`,
                backgroundColor: loadingBannerImage ? "#000000d9" : undefined,
                transition: "2s",
                borderBottom: "1px solid #44444461",
              }}
              className="flex justify-end pt-2.5 pb-1 lg:p-[50px] h-[106px] lg:h-[162px] bg-center bg-cover bg-no-repeat bg-blend-multiply"
            >
              <div className="w-fit h-full flex flex-col justify-between relative">
                <div className="flex gap-3 justify-end">
                  <Button
                    className="h-10 w-10 lg:h-[46px] lg:w-[46px]"
                    shape="circle"
                    onClick={handleShareButton}
                  >
                    <ShareAltOutlined />
                  </Button>
                  {/* <Button
                    className="text-[8px] h-10 w-10 lg:h-[46px] lg:w-[46px] flex justify-center items-center"
                    shape="circle"
                  >
                    <MoreOutlined
                      style={{ transform: "rotate(90deg)" }}
                      className="text-base max-h-4"
                    />
                  </Button> */}
                </div>
                <div
                  onClick={async () => {
                    if (loadingBannerImage) return;
                    (
                      inputElement.current as unknown as HTMLInputElement
                    ).click();
                  }}
                  className="group hidden lg:flex bg-[#0C0C0DA3] text-white hover:text-primary items-center gap-3 rounded-[3px] px-2.5 py-[9px] cursor-pointer"
                >
                  <CameraOutlined className="text-white group-hover:text-primary" />
                  {project?.banner ? `Change Banner` : `Upload Banner`}
                  <input
                    className="hidden"
                    type="file"
                    id="avatar"
                    name="avatar"
                    accept="image/png, image/jpeg"
                    onChange={async ({ target }) => {
                      if (loadingBannerImage) return;
                      setLoadingBannerImage(true);
                      try {
                        if (target?.files?.length) {
                          // do something with the form.
                          // target.form.submit() or whatever
                          console.log(target.files[0]);
                          const formData = new FormData();
                          formData.append("file", target.files[0]);
                          const response = await fetch(
                            `${REACT_APP_BASEURL}/media`,
                            {
                              method: "POST",
                              body: formData,
                            }
                          );
                          console.log({ response });
                          const json = await response.json();
                          console.log({ json });

                          handleUpdate(
                            {
                              banner: json?.data?.file?.url ?? "",
                            },
                            `${project.id}`,
                            {
                              onFinish: ({ id }: Record<string, any>) => {
                                console.log({ id, handleGet });
                                handleGet?.(`${id}/signature`, {
                                  onFinish: (d) => {
                                    setLoadingBannerImage(false);
                                  },
                                });
                              },
                            }
                          );
                        }
                      } catch (error) {
                        setLoadingBannerImage(false);
                      }
                    }}
                    ref={inputElement}
                  />
                </div>
                <div className="lg:hidden flex w-[29px] h-[26px] ml-auto cursor-pointer bg-primary rounded-[3px] itme-center justify-center">
                  <CameraOutlined className="text-white" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Profile */}
        <div className="relative lg:flex lg:pb-24">
          {/* Tab bar */}
          <div className="px-5 lg:hidden mt-6 border-0 border-t-4 border-solid border-[#28303F]">
            <div className="pt-[43px]">
              <div className="w-full flex items-center gap-10 text-[#7C7C7C] list-none overflow-x-auto">
                <div
                  onClick={() => setSelectedForm("network")}
                  className={`relative p-4 ${
                    activeTab["network"] ?? "active:bg-white/5"
                  } text-white whitespace-nowrap`}
                >
                  Manage Network
                  {activeTab?.network && (
                    <div className="absolute bottom-0 left-[0] w-full h-px bg-primary"></div>
                  )}
                </div>
                <div
                  onClick={() => setSelectedForm("whitelistBonus")}
                  className={`relative p-4 ${
                    activeTab["whitelistBonus"] ?? "active:bg-white/5"
                  } text-white whitespace-nowrap`}
                >
                  Whitelist Reward
                  {activeTab?.whitelistBonus && (
                    <div className="absolute bottom-0 left-[0] w-full h-px bg-primary"></div>
                  )}
                </div>
                <div
                  onClick={() => setSelectedForm("volumeBonus")}
                  className={`relative p-4 ${
                    activeTab["volumeBonus"] ?? "active:bg-white/5"
                  } text-white whitespace-nowrap`}
                >
                  Volume Reward
                  {activeTab?.volumeBonus && (
                    <div className="absolute bottom-0 left-[0] w-full h-px bg-primary"></div>
                  )}
                </div>
                <div
                  onClick={() => setSelectedForm("adminBonus")}
                  className={`relative p-4 ${
                    activeTab["adminBonus"] ?? "active:bg-white/5"
                  } text-white whitespace-nowrap`}
                >
                  Admin Reward
                  {activeTab?.adminBonus && (
                    <div className="absolute bottom-0 left-[0] w-full h-px bg-primary"></div>
                  )}
                </div>
                <div
                  onClick={() => setSelectedForm("psacBonus")}
                  className={`relative p-4 ${
                    activeTab["psacBonus"] ?? "active:bg-white/5"
                  } text-white whitespace-nowrap`}
                >
                   {TOKEN_SYMBOL} Community Bonus
                  {activeTab?.psacBonus && (
                    <div className="absolute bottom-0 left-[0] w-full h-px bg-primary"></div>
                  )}
                </div>
                <div
                  onClick={() => setSelectedForm("distributeToken")}
                  className={`relative p-4 ${
                    activeTab["distributeToken"] ?? "active:bg-white/5"
                  } text-white whitespace-nowrap`}
                >
                  Distribute Tokens
                  {activeTab?.distributeToken && (
                    <div className="absolute bottom-0 left-[0] w-full h-px bg-primary"></div>
                  )}
                </div>
                <div
                  onClick={() => setSelectedForm("referralBonus")}
                  className={`relative p-4 ${
                    activeTab["referralBonus"] ?? "active:bg-white/5"
                  } text-white whitespace-nowrap`}
                >
                  Referral Reward
                  {activeTab?.referralBonus && (
                    <div className="absolute bottom-0 left-[0] w-full h-px bg-primary"></div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Overview */}
          <div className="w-full xl:p-20">
            <div className="p-5">
              <div className="flex justify-start lg:justify-start  gap-5">
                <div className={"lg:flex-start  xl:mr-10"}>
                  <div className="justify-end min-w-[277px]  text-base text-white pl-[18px] border border-primary border-solid rounded-lg flex items-center">
                    <p className="hidden sm:block pr-[19px] number-font border-0 border-r border-solid text-[10px] sm:text-xs md:text-sm lg:text-base border-primary py-2 m-0">
                      {project?.projectAddress}
                    </p>
                    <p className="sm:hidden pr-[19px] number-font border-0 border-r border-solid text-[10px] sm:text-sm md:text-sm lg:text-base border-primary py-2 m-0">
                      {project.projectAddress?.slice(0, 15)}......
                      {project.projectAddress?.slice(-15)}
                    </p>
                    <span className="p-2">
                      <CopyToClipboard
                        text={project.projectAddress}
                        onCopy={copyHandler}
                      >
                        {!copied ? (
                          <CopyOutlined className="text-white lg:text-primary w-full h-full flex justify-center cursor-pointer" />
                        ) : (
                          <CheckOutlined className="text-primary w-full h-full flex justify-center cursor-pointer" />
                        )}
                      </CopyToClipboard>
                    </span>
                  </div>
                </div>
                <Button
                  onClick={handleOponProject}
                  className="hidden lg:block border-primary bg-transparent  text-base font-medium h-10"
                >
                  View Fundraiser Page
                </Button>
              </div>

              {/* Tab Body */}
              {selectedForm === ManageProjectForms.network &&
                (!showTokenScreen ? (
                  <ManageNetworkComponent
                    switchingNetwork={switchingNetwork}
                    project={project}
                    handleManageToken={handleShowTokenScreen}
                    projectOwners={projectOwners}
                    networks={networks}
                    loadingDeploy={loadingDeploy}
                    handleDeploy={handleDeploy}
                    currentNetwork={currentNetwork}
                    handleSelectAvailableNetwork={handleSelectAvailableNetwork}
                    loadingNetworkSelect={loading}
                    fees={fees}
                    usdDecimals={usdDecimals}
                    handleNetworkSelect={handleNetworkSelect}
                  />
                ) : (
                  <ManageTokenLoadedComponent
                    handleBackButton={handleShowTokenScreen}
                    projectOwners={projectOwners}
                    networks={networks}
                    loadingNetworkSelect={loading}
                    loadingAddToken={loadingAddToken}
                    loadingWithdrawToken={uiLoaders["withdraw"]}
                    handleAddNewToken={handleAddNewToken}
                    handleRemoveTokenSubmit={handleRemoveTokenSubmit}
                    handleWidthdrawToken={handleWidthdrawToken}
                    selectedTokenAddress={selectedTokenAddress}
                    currentNetwork={currentNetwork}
                    acceptedTokens={acceptedTokens}
                    totalSacUSD={projectInfo?.totalContributed ?? ""}
                    listData={listData}
                  />
                ))}

              {selectedForm === ManageProjectForms.whitelistBonus &&
                (projectUtils?.features?.find(
                  (item) => item.id == "whitelistBonus"
                )?.enabled ? (
                  <WhitelistComponent
                    handleNewGroup={handleNewGroup}
                    handleManageGroup={handleManageGroup}
                    initComponent={initWhitelistComponent}
                    loading={loadingWhitelist}
                    allWhitelists={allWhitelists}
                  />
                ) : (
                  <UnlockExtraFeatureButton
                    onClick={() => setShowFeatureModal(true)}
                  />
                ))}

              {selectedForm === ManageProjectForms.volumeBonus &&
                (projectUtils?.features?.find(
                  (item) => item.id == "volumeBonus"
                )?.enabled ? (
                  <VolumeBonusComponent
                    handleNewVolumeBonus={_handleNewVolumeBonusToggle}
                    handleManageVolumeBonus={handleManageVolumeBonus}
                    loading={loading}
                    listData={(project.volumeBonus ?? []) as any}
                  />
                ) : (
                  <UnlockExtraFeatureButton
                    onClick={() => setShowFeatureModal(true)}
                  />
                ))}

              {selectedForm === ManageProjectForms.adminBonus && (
                <AdminBonusComponent
                  handletoggleAdminBonusModal={() => {
                    setShowNewAdminBalance((old) => !old);
                  }}
                  handleManageAdminBonus={handleManageAdminBonus}
                  selectedAdminBonus={selectedAdminBonus}
                  loading={
                    uiLoaders["addAdminBonus"] || uiLoaders["cancelAdminBonus"]
                  }
                  adminBonuses={adminBonuses ?? []}
                />
              )}
              {selectedForm === ManageProjectForms.psacBonus && (
                <PSacBonusComponent
                  project={project}
                  loading={loading}
                  onFinish={handleUpdatePsac}
                />
              )}
              {selectedForm === ManageProjectForms.distributeToken && (
                <DistributeTokenComponent
                  distributedTokenLoaded={distributedTokenLoaded}
                  handleClaimPercentage={handleClaimPercentage}
                  onFinish={handleStartDistribution}
                />
              )}
              
              {selectedForm === ManageProjectForms.referralBonus &&
                (projectUtils?.features?.find(
                  (item) => item.id == "referralBonus"
                )?.enabled ? (
                  <ReferralBonusComponent
                    project={project}
                    loading={loading}
                    onFinish={handleUpdateProject}
                  />
                ) : (
                  <UnlockExtraFeatureButton
                    onClick={() => setShowFeatureModal(true)}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      <AddTokenModal
        currentNetwork={currentNetwork as NETWORKS_TYPE}
        visible={showAddTokenModal}
        onFinish={onFinish}
        onClose={onClose}
      />
      <ShareModal
        onCopied={() => {
          copyToClipboard(`${window.location.origin}/sac/${project.id ?? ""}`);
          
        }}
        visible={showShareModal}
        text={`${window.location.origin}/sac/${project.id ?? ""}`}
        onClose={onClose}
      />
      <NewGroupModal
        visible={showNewGroup}
        onFinish={onFinishNewGroup}
        onClose={onClose}
      />
      <ManageWhitelistModal
        visible={showManageGroup}
        onFinish={(data: any) => {
          handleManageGroupSubmit(data);
          setShowManageGroup(false);
        }}
        onClose={onClose}
        selectedGroup={selectedGroup}
      />
      <NewVolumeBonusModal
        visible={showNewVolumeBalance}
        selectedVolumeBonus={selectedVolumeBonus}
        loading={loading}
        onFinish={(data: any) => {
          handleNewVolumeBonus(data);
          setShowNewVolumeBalance(false);
        }}
        onClose={onClose}
      />

      <NewAdminBonusModal
        visible={showNewAdminBalance}
        selectedAdminBonus={undefined}
        loading={uiLoaders["addAdminBonus"]}
        onFinish={(data: any) => {
          handleManageAdminBonus(data, "create");
          setShowNewAdminBalance(false);
        }}
        onClose={onClose}
      />
      <ClaimPercentageModal
        project={project}
        visible={showDistributionToken}
        onClose={onClose}
      />
      <ExtraFeatureModal
        onClose={() => setShowFeatureModal(false)}
        loadUtils={fns.loadUtils}
        onFinish={handleOnActivateFeature}
        project={project}
        loading={loading}
        visible={showFeatureModal}
      />
    </div>
  );
};

export default ManageProject;
ManageProject.renderClassName = "--akdjfkdjkjkj";
