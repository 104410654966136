import Web3 from "web3";
import BN from 'bn.js';
// var sigUtil = require('eth-sig-util')

export function ellipseAddress(address = "", width = 10): string {
  if (!address) {
    return "";
  }
  return `${address.slice(0, width)}...${address.slice(-width)}`;
}

export function signMsg(msgParams: any, from: any) {
  if (!window || !window.web3) return null;
  window.web3.currentProvider.sendAsync(
    {
      method: "eth_signTypedData",
      params: [msgParams, from],
      from: from,
    },
    function (err: any, result: any) {
      if (err) return console.error(err);
      if (result.error) {
        return console.error(result.error.message);
      }
      console.error(result);
    }
  );
}

export function cleanInput(v: string) {
  return (
    String(v)
      ?.replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1") || ""
  );
}
export const toBN = (bn: string) => {
  return  Web3.utils.toBN(bn);
}
export const toEther = function (
  amount: string | BN,
  decimals = 5,
  currencyDecimals = 18
) {
  amount = amount ? String(amount) : "0";
  if (!amount || isNaN(parseInt(amount))) return "0";
  const diff = 18 - currencyDecimals;
  const multi = Web3.utils.toBN("10").pow(Web3.utils.toBN(diff));
  const value = Web3.utils.fromWei(
    Web3.utils.toBN(amount).mul(multi).toString()
  );
  if (decimals == -1) return value;
  if (!value.includes(".")) return value;
  return value.substring(0, value.indexOf(".") + decimals + 1);
};

export const getContractData = (
  data: any,
  contractName: string,
  key: string,
  toParse = false
) => {
  const contractData = data[contractName];
  let value = null;
  if (contractData) {
    value = contractData[key];
    if (toParse && value) {
      return toEther(value);
    }
  }
  return value;
};

export const amountInWei = (value: string) => {
  return Web3.utils.toWei(value);
};
