import {
  CheckCircleOutlined,
  CaretDownOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
  theme,
} from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { ProjectNamespace } from "../../../_shared/namespaces/project";
import {
  NETWORKS_TYPE,
  PSAC_TOKEN_ADDRESS,
  SELECT_A_TOKEN,
  TOKEN_SYMBOL,
  cleanInput,
  computeCurrentPhaseEndDate,
  computeEndDate,
  moneyFormat,
  resolveNetworkObject,
} from "../../../_shared";
import Countdown from "react-countdown";
import { toEther } from "../../../web3/libs/utilities";
import {
  AcceptedToken,
  GetBonusStat,
  UserDataStat,
} from "../../../_shared/utils/type";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "antd/es/form/Form";
import _ from "lodash";
import { WalletConnectorContext } from "../../../contexts";
import { Link, useLocation, useSearchParams } from "react-router-dom";
const { Option } = Select;
const { Text } = Typography;
const { useToken } = theme;
const items: MenuProps["items"] = [
  {
    label: <a href="https://www.antgroup.com">1st menu item</a>,
    key: "0",
  },
  {
    label: <a href="https://www.aliyun.com">2nd menu item</a>,
    key: "1",
  },
  {
    type: "divider",
  },
  {
    label: "3rd menu item",
    key: "3",
  },
];

interface SacrificeSectionProps {
  project?: ProjectNamespace.Project;
  sacrificePhaseDay?: string;
  pointRate?: string;
  userDataStats?: UserDataStat;
  currentNetwork?: NETWORKS_TYPE;
  loadingNetworkSwitch: boolean;

  acceptedTokens?: AcceptedToken[];
  bonusData?: GetBonusStat;
  sacData: {
    amount?: string;
    uplineAddress?: string;
    tokenAddress?: string;
    dollarValue?: string;
    calcPoints?: number;
  };

  handleChange: (
    address: string,
    amount: string,
    referalAddress?: string,
    delay?: number
  ) => void;
  handleOnFinish: (data?: any) => void;

  onComplete: () => void;
  handleSwitchNetwork: (any?: any) => void;

  uiLoaders: Record<any, boolean>;
  deployToggle: boolean;
  loading: boolean;
  tokenBalance?: string;
  usdDecimals?: number;
  address?: string;
  amountError?: string;
  referralError?: string;
}
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
export const SacrificeSection = (props: SacrificeSectionProps) => {
  const { token } = useToken();
  const {
    sacrificePhaseDay,
    handleChange,
    amountError,
    referralError,
    pointRate,
    acceptedTokens,
    bonusData,
    uiLoaders,
    loading: isloadingBonuses,
    project,
    loadingNetworkSwitch,
    currentNetwork,
    userDataStats,
    sacData,
    address,
    handleOnFinish,
    handleSwitchNetwork,
    usdDecimals,
    tokenBalance,
    onComplete,
  } = props;
  const { connect } = useContext(WalletConnectorContext);
  const query = useQuery();
  const ref = query.get("pid");


  const [selectedToken, setSelectedToken] = useState(SELECT_A_TOKEN);
 
  useEffect(() => {
    if (form.getFieldValue("amount")?.length > 0) {
      handleInputChange(form.getFieldValue("amount"));
    }
  }, [address]);

  const makeSacrifice = async () => {
    const finish = await handleOnFinish();
    console.log("FINISHEEEE", finish)
   // clearForm();
  };

  const loadingSacrificingProcess =
    uiLoaders["approve"] ||
    uiLoaders["allowance"] ||
    uiLoaders["sacrificeWithBonus"];
  const loading =
    !!selectedToken &&
    selectedToken !== SELECT_A_TOKEN &&
    (uiLoaders["dollarValue"] || uiLoaders["calculatePointToken"]);

  const disableSacrificeButton =
    !_.isEmpty(amountError) ||
    isloadingBonuses ||
    loading ||
    !sacData.calcPoints || !selectedToken;
  const [form] = useForm();
  const [referallForm] = useForm();
  const donationPoints = sacData.calcPoints;
  const [dollarVal, setDollarVal] = useState("");

  let oldDollarVal = "";
  const loadingBonuses =
    isloadingBonuses && selectedToken && selectedToken !== SELECT_A_TOKEN;

  useEffect(() => {
    if (loadingBonuses || loading || !sacData.dollarValue) {
      oldDollarVal = String(dollarVal);
      setDollarVal("");
    } else {
      if (oldDollarVal !== sacData.dollarValue) {
        setDollarVal(sacData.dollarValue);
      }
    }
  }, [sacData.dollarValue, loadingBonuses]);
  useEffect(() => {
    form.setFieldsValue({
      referalAddress: userDataStats?.uplineAddress ?? "",
    });
  }, [userDataStats]);

  useEffect(() => {
    setSelectedToken(SELECT_A_TOKEN);
  }, [currentNetwork]);

  useEffect(() => {
    if (!ref) return;
    console.log({ ref });
    setTimeout(() => {
      form.setFieldsValue({ referalAddress: ref });
    }, 5000);
  }, [ref]);

  const onChange = (data: any) => {
    setSelectedToken(data);
    handleChange(
      data,
      form.getFieldValue("amount"),
      form.getFieldValue("referalAddress")
    );
    // console.log('data', data, form.getFieldValue("amount") )
  };
  const clearForm = () => {
    form.setFieldsValue({ amount: "" });
  };

  const handleInputChange = (_: any) => {
    console.log("SELCETEDD", selectedToken, address);
    if (selectedToken == SELECT_A_TOKEN || !address) return;

    const clean = cleanInput(form.getFieldValue("amount"));
    if (form.getFieldValue("amount") !== clean) {
      form.setFieldsValue({ amount: clean });
      return;
    }
    handleChange(
      selectedToken,
      form.getFieldValue("amount"),
      form.getFieldValue("referalAddress"),
      1000
    );
  };

  const handleInputReferrerChange = useCallback(
    (_: any) => {
      handleChange(
        selectedToken,
        form.getFieldValue("amount"),
        form.getFieldValue("referalAddress"),
        1000
      );
    },
    [form, referallForm, selectedToken]
  );
  const tokenList = (acceptedTokens ?? []).reduce(
    (prev, token, index) =>
      prev +
      `${token.symbol === "" ? currentNetwork?.nativeToken : token.symbol}${
        (acceptedTokens ?? []).length - 1 !== index ? ", " : ""
      }`,
    ""
  );

  const Completionist = () => <Card>Project Contribution Completed!</Card>;
  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      return (
        <div className="flex gap-2">
          <Text
            className="font-bold p-2 rounded"
            style={{ backgroundColor: token.colorPrimary }}
          >
            {String(days).padStart(2, "0")}
          </Text>
          <Text
            className="font-bold p-2 rounded"
            style={{ backgroundColor: token.colorPrimary }}
          >
            {String(hours).padStart(2, "0")}
          </Text>
          <Text
            className="font-bold p-2 rounded"
            style={{ backgroundColor: token.colorPrimary }}
          >
            {String(minutes).padStart(2, "0")}
          </Text>
          <Text
            className="font-bold p-2 rounded"
            style={{ backgroundColor: token.colorPrimary }}
          >
            {String(seconds).padStart(2, "0")}
          </Text>
        </div>
      );
    }
  };
  const phases = project?.phases ?? [];
  let countDownRenderer = <></>;
  let countdownTitle = "";
  let descTitle = "";
  let descText = "";
  let countDownTime = (project?.startDayTime ?? 1) * 1000;
  const startDate = new Date((project?.startDayTime ?? 1) * 1000);
  const endDate = new Date(computeEndDate(project?.startDayTime ?? 0, phases));


  useEffect(() => {
    if (!sacrificePhaseDay) {
      setLocked(true)
     
    }
    if (
      project?.id &&
      Date.now() > startDate.getTime() &&
      Date.now() < endDate.getTime()
    ) {
      setLocked(false)
    }
  }, [project?.id, startDate, endDate])

  if (
    project?.id &&
    Date.now() > startDate.getTime() &&
    Date.now() < endDate.getTime()
  ) {
    countDownTime = project.phases.reduce((prv, current) => {
      if (prv >= Date.now()) {
        return prv;
      }
      return current.duration * 24 * 60 * 60 * 1000 + prv;
    }, startDate.getTime());
    countdownTitle = "Current Phase Ends In";
    descTitle = "Fundraiser Day";
    descText = sacrificePhaseDay ?? "";
    
  }
  const  [locked, setLocked] = useState<boolean>(true);

  useEffect(() => {
    form.setFieldsValue({
      referalAddress: userDataStats?.uplineAddress ?? "",
    });
  }, [userDataStats]);
  useEffect(() => {
    setSelectedToken(SELECT_A_TOKEN);
  }, [currentNetwork]);

  if (project?.id && Date.now() < startDate.getTime()) {
    // countdownTitle = "Project Starts In";
    countdownTitle = "";
    descTitle = "Starts";
    descText = startDate.toLocaleString();
  }

 
 

  if (project && Date.now() > endDate.getTime()) {
    countDownRenderer = (
      <>
        <Card>
          <h2 style={{ textAlign: "center", color: "orange", padding: 20 }}>
            Fundraiser Ended!
          </h2>
        </Card>
      </>
    );
    descTitle = "Ended";
    descText = endDate.toLocaleString();
  } else {
    countDownRenderer = (
      <Countdown
        date={countDownTime}
        renderer={renderer}
        onComplete={onComplete}
      />
    );
  }



  const selectAfter = (
    <Select
      // disabled={loading}

      onChange={onChange}
      suffixIcon={<CaretDownOutlined />}
      defaultValue={SELECT_A_TOKEN}
      value={selectedToken}
      className="bg-transparent"
    >
      {(acceptedTokens ?? []).map((token, index) => (
        <Option value={token.token} key={index}>
          {token.symbol === "" ? currentNetwork?.nativeToken : token.symbol}
        </Option>
      ))}
    </Select>
  );

  const resolvedNetworks: NETWORKS_TYPE[] = resolveNetworkObject(
    project?.deployedNetworks ?? []
  );
  const networkTokensMenu: MenuProps["items"] = resolvedNetworks.map(
    (network, key) => {
      return {
        label: <Text>{network.label}</Text>,
        key,
        onClick: () => handleSwitchNetwork(network),
      };
    }
  );
  const isPsacToken =
    selectedToken.toLowerCase() === PSAC_TOKEN_ADDRESS.toLowerCase();

  const __total =
    Number(donationPoints) +
    Number(bonusData?.volumeBonus ?? 0) +
    Number(bonusData?.whitelistBonus ?? 0) +
    Number(bonusData?.refereeBonus ?? 0) +
    Number(bonusData?.stakeBonus ?? 0) +
    (isPsacToken ? Number(bonusData?.psacBonus ?? 0) : 0);

  return (
    <div
      className="flex w-full flex-col relative sac rounded-b-[20px]"
      style={{ backgroundColor: token.colorBgContainer }}
    >
      {/* layout 1 */}
      <div className="sac-btn w-fit self-center px-5 py-3 absolute rounded-lg top-[-18px]">
        <Text className="font-bold">Contribute</Text>
      </div>
      {/* layout 2 */}
      <div className="flex  mt-[42px] mb-[35px] mx-[36px] flex-wrap justify-between items-center">
        <div
          className="w-fit self-center px-5 py-3 flex flex-col items-center"
          style={{ backgroundColor: token.colorBgContainerDisabled }}
        >
          <Text>{descTitle}</Text>
          <Text className="font-bold number-font">{descText}</Text>
        </div>
        <div className="flex flex-col gap-2 items-center">
          <Text className="font-bold"> {countdownTitle}</Text>
          {countDownRenderer}
        </div>
      </div>
      {/* layout 3 */}
      <div className="flex py-[31px] px-[27px] justify-between top-curve rounded-t-[20px] mx-5">
        <div className="flex flex-col gap-2 items-center">
          <Text
            className="text-[16px]"
            style={{ color: token.colorTextDisabled }}
          >
            {" "}
            Your Contribution
          </Text>
          <Text
            className={`font-bold text-2xl number-font ${
              uiLoaders?.loadingUserData ? "" : ""
            }`}
          >
            {" "}
            $
            {moneyFormat(
              toEther(String(userDataStats?.totalContributed ?? 0)),
              2
            )}
          </Text>
        </div>
        {uiLoaders?.loadingUserData && (
          <div className="flex flex-row items-center align-center">
            <Spin />
          </div>
        )}

        <div className="flex flex-col gap-2 items-center">
          <Text
            className="text-[16px]"
            style={{ color: token.colorTextDisabled }}
          >
            {" "}
            Your Points
          </Text>
          <Text
            className={`font-bold text-2xl number-font ${
              uiLoaders?.loadingUserData ? "" : ""
            }`}
          >
            {" "}
            {moneyFormat(userDataStats?.totalPoints, 0)}
          </Text>
        </div>
      </div>
      {/* layout 4 */}
      <div className="flex flex-col pt-[42px] pb-[35px] px-[27px] justify-between top-curve rounded-b-[20px] gap-5">
        <div className="flex gap-2 items-center justify-between">
          <Text className="text-lg">Choose Network</Text>
          <Dropdown
            disabled={loadingNetworkSwitch}
            menu={{ items: networkTokensMenu }}
            trigger={["click"]}
          >
            <Button
              type="ghost"
              className=""
              style={{ border: "1px solid grey" }}
              onClick={(e) => e.preventDefault()}
            >
              <Text
                style={{
                  color: currentNetwork
                    ? token.colorText
                    : token.colorTextDisabled,
                }}
              >
                {currentNetwork?.label}{" "}
                {loadingNetworkSwitch ? (
                  <Spin />
                ) : (
                  <Space>
                    Select <DownOutlined />
                  </Space>
                )}
              </Text>
            </Button>
          </Dropdown>
        </div>
        <div className="flex gap-2 items-center justify-between">
          <Text className="text-[16px]">Current Point Rate</Text>
          <Text className="text-[16px] font-bold number-font">
            {moneyFormat(Number(pointRate), 0)} points/USD
          </Text>
        </div>
        <Form
          name="form_one"
          form={form}
          layout="vertical"
          // form={form}
          // onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label={
              <Row style={{ width: "100%" }} justify="space-between">
                <Col>Amount </Col>
                {!_.isEmpty(tokenBalance) && (
                  <Col
                    onClick={() => {
                      form.setFieldsValue({
                        amount: toEther(String(tokenBalance)),
                      });
                    }}
                    className="justify-center align-items-center ml-3 number-font text-blue-600"
                    // style={{ color: "grey" }}
                  >
                    Bal: {moneyFormat(toEther(String(tokenBalance)), 2)}
                  </Col>
                )}
              </Row>
            }
            name="amount"
            extra={
              <>
                {amountError && (
                  <Text style={{ fontSize: "11px" }} className="error-text">
                    {amountError}
                  </Text>
                )}
                {!_.isEmpty(dollarVal) &&
                  !loading &&
                  !amountError &&
                  dollarVal !== "0" && (
                    <Text style={{ color: "grey" }} className="number-font">
                      ~ ${moneyFormat(toEther(dollarVal, 2, usdDecimals), 2)}
                    </Text>
                  )}
              </>
            }
          >
            <Input
              autoComplete="off"
              disabled={loading || locked}
              onChange={handleInputChange}
              placeholder="e.g 10000"
              addonAfter={selectAfter}
            />
          </Form.Item>
          <Form.Item
            label="Referral Address"
            name={"referalAddress"}
            rules={[{ message: "Please input address!" }]}
            extra={
              <>
                {referralError && (
                  <Text style={{ fontSize: "11px" }} className="error-text">
                    {referralError}
                  </Text>
                )}
              </>
            }
          >
            <Input
              onChange={handleInputReferrerChange}
              disabled={
                loadingSacrificingProcess ||
                !_.isEmpty(userDataStats?.uplineAddress) ||
                locked
              }
              placeholder="Enter Referral Address"
            />
          </Form.Item>

          <Form.Item style={{ marginTop: "24px" }}>
            <Row>
              <Button
                size="large"
                onClick={address ? makeSacrifice : connect}
                disabled={address ? (disableSacrificeButton || locked) : false}
                loading={loadingSacrificingProcess}
                type="primary"
                className="bg-success"
                shape="round"
                htmlType="submit"
                style={{ marginLeft: "auto" }}
              >
                {address ?  ('Contribute') : 'Connect Wallet'}
              </Button>
            </Row>
          </Form.Item>
          <div className="flex flex-col gap-5">
            <div className="flex gap-2 items-center justify-between">
              <Text className="text-sm">Direct Points</Text>
              {_.isEmpty(amountError) ? (
                <>
                  {loadingBonuses ? (
                    <Spin size="small" />
                  ) : (
                    <Text className="text-sm">
                      {moneyFormat(Number(sacData.calcPoints))}
                    </Text>
                  )}
                </>
              ) : (
                <Text className="text-sm">0</Text>
              )}
            </div>
            <div className="flex gap-2 items-center justify-between">
              <Tooltip title={`Stake  ${TOKEN_SYMBOL} token to enjoy this bonus.`}>
                <Text className="text-sm" style={{ color: "orange" }}>
                {TOKEN_SYMBOL} Stake Bonus <ExclamationCircleFilled />
                </Text>{" "}
                <Link className="ml-2" to="/stake">
                  <b>Stake Now</b>
                </Link>
              </Tooltip>
              {_.isEmpty(amountError) ? (
                <>
                  {loadingBonuses ? (
                    <Spin size="small" />
                  ) : (
                    <Text className="text-sm number-font">
                      {moneyFormat(Number(bonusData?.stakeBonus ?? 0))}
                    </Text>
                  )}
                </>
              ) : (
                <Text className="text-sm number-font">0</Text>
              )}
            </div>
            <div className="flex gap-2 items-center justify-between">
              <Tooltip title={`Contribute  ${TOKEN_SYMBOL} token to enjoy this bonus`}>
                <Text style={{ color: "orange" }} className="text-sm">
                {TOKEN_SYMBOL} Token Bonus{" "}
                </Text>
              </Tooltip>
              {_.isEmpty(amountError) ? (
                <>
                  {loadingBonuses ? (
                    <Spin size="small" />
                  ) : (
                    <Text className={`text-sm number-font`}>
                      {!isPsacToken ? (
                          <Tooltip title={`Contibute ${TOKEN_SYMBOL} token to enjoy this bonus`}>
                          <ExclamationCircleFilled
                            style={{ color: "orange" }}
                          />
                        </Tooltip>
                      ) : (
                        ""
                      )}{" "}
                      <span style={{ opacity: isPsacToken ? 1 : 0.4 }}>
                        {moneyFormat(Number(bonusData?.psacBonus ?? 0))}
                      </span>
                    </Text>
                  )}
                </>
              ) : (
                <Text className="text-sm number-font">0</Text>
              )}
            </div>
            <div className="flex gap-2 items-center justify-between">
              <Text className="text-sm">Volume Reward</Text>
              {_.isEmpty(amountError) ? (
                <>
                  {loadingBonuses ? (
                    <Spin size="small" />
                  ) : (
                    <Text className="text-sm number-font">
                      {moneyFormat(Number(bonusData?.volumeBonus ?? 0))}
                    </Text>
                  )}
                </>
              ) : (
                <Text className="text-sm">0</Text>
              )}
            </div>
            <div className="flex gap-2 items-center justify-between">
              <Text className="text-sm">Whitelist Reward</Text>
              {_.isEmpty(amountError) ? (
                <>
                  {loadingBonuses ? (
                    <Spin size="small" />
                  ) : (
                    <Text className="text-sm number-font">
                      {moneyFormat(Number(bonusData?.whitelistBonus ?? 0))}
                    </Text>
                  )}
                </>
              ) : (
                <Text className="text-sm">0</Text>
              )}
            </div>
            <div className="flex gap-2 items-center justify-between">
              <Text className="text-sm">Referee Bonus</Text>
              {_.isEmpty(amountError) ? (
                <>
                  {loadingBonuses ? (
                    <Spin size="small" />
                  ) : (
                    <Text className="text-sm number-font">
                      {moneyFormat(Number(bonusData?.refereeBonus ?? 0))}
                    </Text>
                  )}
                </>
              ) : (
                <Text className="text-sm number-font">0</Text>
              )}
            </div>

            <div className="flex gap-2 items-center justify-between">
              <Text className="text-sm font-extrabold">Total</Text>
              {_.isEmpty(amountError) ? (
                <>
                  {loadingBonuses ? (
                    <Spin size="small" />
                  ) : (
                    <Text className="text-sm number-font">
                      {moneyFormat(__total)}
                    </Text>
                  )}
                </>
              ) : (
                <Text className="text-sm number-font">0</Text>
              )}
            </div>
          </div>
        </Form>

        <Row
          justify="center"
          gutter={[2, 16]}
          align="middle"
          style={{ flexDirection: "column" }}
        >
          <Col>
            <Text className="disabled">Accepted Tokens</Text>
          </Col>
          <Col>
            <Text className="font-extrabold">{tokenList}</Text>
          </Col>
        </Row>
        {loading && (
          <span className="spin-wrapper">
            <Spin size="large" />
          </span>
        )}
      </div>
    </div>
  );
};
