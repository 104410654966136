import { Tabs, theme } from "antd";
import { ProjectNamespace } from "../../_shared/namespaces/project";
import { ConnectButton } from "./connect-button";
import { CoverContainer } from "./index.styled";
import { ProjectTab } from "./project";
import { useEffect } from "react";
import {  useNavigate, useParams } from "react-router-dom";
const { useToken } = theme;

interface MyProjectsProps {
  loadingData: Record<string, boolean>;
  projectData: Record<string, ProjectNamespace.Project[]>;
  paginationData: Record<
    string,
    {
      totalCount: number;
      perPage: number;
      current: number;
      next?: number;
    }
  >;
  handleMyProjectPaginationFns: Record<
    string,
    (page: number, pageSize: number) => void
  >;

  loadingConnect: boolean;
  address?: string;
  handleSearchChange: (data: any, key?: string) => void;
  handleConnect: () => void;
}

export const MyProjects = (props: MyProjectsProps) => {
  const { token } = useToken();
  const {
    projectData,
    loadingData,
    paginationData,
    handleMyProjectPaginationFns,
    loadingConnect,
    address,
    handleConnect,
    handleSearchChange,
  } = props;
  const {tab} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    console.log({tab});
  }, [tab]);
  return (
    <CoverContainer token={token} className="pt-20">
      <Tabs
        activeKey={tab}
        onChange={(activeTab) => navigate(`/projects/${activeTab}`)}
        items={[
          {
            key: "created",
            label: "Created Projects",
            children: address ? (
              <ProjectTab
                handleSearchChange={(data) => {
                  handleSearchChange(data, "projects");
                }}
                projectList={projectData["projects"] ?? []}
                loading={loadingData["projects"] ?? false}
                pagination={paginationData["projects"]}
                onPaginationChange={handleMyProjectPaginationFns["projects"]}
                showManage
              />
            ) : (
              <ConnectButton
                loading={loadingConnect}
                handleConnect={handleConnect}
              />
            ),
          },
          {
            key: "sacrifices",
            label: "My Contributions",
            children: address ? (
              <ProjectTab
                handleSearchChange={(data) => {
                  handleSearchChange(data, "getDonorProjects");
                }}
                projectList={projectData["getDonorProjects"] ?? []}
                loading={loadingData["getDonorProjects"] ?? false}
                pagination={paginationData["getDonorProjects"]}
                onPaginationChange={
                  handleMyProjectPaginationFns["getDonorProjects"]
                }
                useDonation
                showStartDate={false}
                showEndDate={false}
                showAmountRaised={false}
              />
            ) : (
              <ConnectButton
                loading={loadingConnect}
                handleConnect={handleConnect}
              />
            ),
          },
          {
            key: "following",
            label: "Following",
            children: address ? (
              <ProjectTab
                handleSearchChange={(data) => {
                  handleSearchChange(data, "following");
                }}
                projectList={projectData["following"] ?? []}
                loading={loadingData["following"] ?? false}
                pagination={paginationData["following"]}
                onPaginationChange={handleMyProjectPaginationFns["following"]}
              />
            ) : (
              <ConnectButton
                loading={loadingConnect}
                handleConnect={handleConnect}
              />
            ),
          },
        ]}
      />
    </CoverContainer>
  );
};
