import { Typography, Row, Col, Space, } from "antd";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { APP_NAME, TOKEN_SYMBOL } from "../../../../../_shared";

ChartJS.register(ArcElement, Tooltip, Legend);


const { Text, Title } = Typography;
;



interface GraphSectionProps {
    text?: string;
}
export const GraphSection = (props: GraphSectionProps) => {
    const { } = props;
    
    const data : ChartData<"doughnut", number[], unknown> = {
        labels: ['Public', 'Marketing', 'Liquidity', 'Liquidity', 'Team', 'Others'],
        datasets: [
          {
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            
            backgroundColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
              ],
            borderWidth: 1,
          },
        ],
      };

    const options : any = {
        plugins: {
          legend: {
            position: 'left',
            
            labels: {
                padding: 30,
                usePointStyle: true,
                pointStyle: 'rect',
                font: {
                    size: 18,
                    lineHeight: 28,
                    weight: 400,
                }
            } 
          } as any,
        } as any
      }
    
    return (<>
                
                <Row className="ant-row-center graph-header" id="tokenomics">
                    <Title level={2} style={{}}>
                    {TOKEN_SYMBOL} Tokenomics
                    </Title>
                    <Col span={24} className="m-point-text"><Text>Enter Brief Description here</Text></Col>
                </Row>

                <Row className="chart-container">
                    <Col className="chart-col" xs={24} md={18} lg={16}>
                        <Doughnut className="desktop-chart" data={data} options={options}
                          plugins={
                            [
                                {
                                    id: 'legendDistance',
                                    beforeInit(chart:any) {
                                        // Get reference to the original fit function
                                        const originalFit = chart.legend.fit;
                                        // Override the fit function
                                        chart.legend.fit = function fit() {
                                            // Call original function and bind scope in order to use `this` correctly inside it
                                            originalFit.bind(chart.legend)();
                                            // Specify what you want to change, whether the height or width
                                            this.width += 200;
                                        }
                                    }
                                }
                            ]
                        } />
                        <Doughnut className="mobile-chart" data={data} options={
                            {
                                ...options,
                                plugins:{
                                    ...options.plugins,
                                    legend:{
                                        ...options.plugins.legend,
                                        position: 'bottom',
                                        labels:{
                                            ...options.plugins.legend.labels,
                                            font:{
                                                ...options.plugins.legend.labels.font,
                                                size: 16,
                                            }   
                                        }
                                    }
                                }
                            }
                            }
                             />
                    </Col>
                </Row>

                <Space className="box-message" >
                    <Text>There will only be a total of 10,000,000  {TOKEN_SYMBOL} tokens in circulation. Stake  {TOKEN_SYMBOL} to share in platform revenue. Revenue is generated from fees paid by projects that use the {APP_NAME} Dapp.</Text>
                </Space>

                
            </>)
};


