import { Action } from "../../../redux/types";

import { ContractMetaData, NETWORKS, NETWORKS_TYPE } from "../../constants";
import { first, get, has, last, reduce, set } from "lodash";
import cookie from "js-cookie";
import { FormInstance, message, notification, Tag } from "antd";
import { ProjectNamespace } from "../../namespaces/project";

export const classname = (classes: Record<string, boolean>) => {
  return Object.keys(classes)
    .filter((clsKey) => classes[clsKey])
    .join(" ");
};

export function getNewLoadingState(
  currentState: Record<string, any> = {},
  action: Action,
  value: any
) {
  const { key } = action;
  return Object.assign({}, currentState, {
    uiLoaders: { ...currentState.uiLoaders, [key]: value },
  });
}

export const arrayToById = (array: any[] = [], key = "_id") => {
  return array.reduce((accumulator, currentObject) => {
    accumulator[get(currentObject, key)] = currentObject;
    return accumulator;
  }, {});
};

export const onBeforeImageUpload = (file: any, size = 2) => {
  const imageTypes = [
    "image/gif",
    "image/jpeg",
    "image/png",
    "image/webp",
    "image/jpg",
    "model/gltf-binary",
    "model/gltf+json",
  ];
  const isJpgOrPng = imageTypes.includes(file.type);
  if (!isJpgOrPng) {
    notification.error({
      message: "Only images are allowed",
      placement: "bottomLeft",
      duration: 4,
    });
    return isJpgOrPng;
  }
  const isLessThanSize = file.size / 1024 / 1024 < Number(size.toFixed(0));
  if (!isLessThanSize) {
    notification.error({
      message: `File must smaller than ${size.toFixed(0)}MB!`,
      placement: "bottomLeft",
      duration: 4,
    });
    return isLessThanSize;
  }
  return true;
};

export const tagRender = (props: any) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3, display: "flex" }}
    >
      {label}
    </Tag>
  );
};

export const normalizeFileUpload = (e: any) => {
  // console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }

  return e && get(e.file, "response.data.file.url");
};

export const copyToClipboard = (text: string) => {
  if (
    navigator?.permissions &&
    navigator?.permissions?.query &&
    navigator.clipboard
  ) {
    navigator.permissions
      .query({ name: "clipboard-write" } as unknown as PermissionDescriptor)
      .then((result) => {
        if (result.state == "granted" || result.state == "prompt") {
          navigator.clipboard.writeText(text).then(
            function () {
              notification.success({
                message: "Copied to clipboard",
                placement: "bottomLeft",
                duration: 4,
              });
            },
            function () {
              console.log("Failed to set clipboard");
            }
          );
        }
      });
    return;
  }

  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(
      function () {
        notification.success({
          message: "Copied to clipboard",
          placement: "bottomLeft",
          duration: 4,
        });
      },
      function () {
        console.log("Failed to set clipboard");
      }
    );
    return;
  }

  // Fallback if no clipboard support
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand("copy");
  notification.success({
    message: "Copied to clipboard",
    placement: "bottomLeft",
    duration: 4,
  });
  document.body.removeChild(textArea);
};

/**
 * Popup Container for ant design modals and popovers
 */
export const getPopupContainer = () => {
  const element = document.createElement("div");
  element.style.position = "fixed";
  element.style.top = "0";
  element.style.zIndex = "999";
  document.body.appendChild(element);
  return element;
};

// export const dynCryptoIconImport = (name: string) => {
//   try {
//     const Component = dynamic<HTMLAttributes<SVGElement>>(
//       () =>
//         import(
//           `node_modules/cryptocurrency-icons/svg/white/${toLower(name)}.svg`
//         ),
//       { ssr: true }
//     );
//
//     return (
//       <div className={'h-100 meta-flex meta-align-center meta-flex-j-c'}>
//         <Component height={20} width={20} viewBox={'0 0 32 32'} />
//       </div>
//     );
//   } catch (e) {
//     return null;
//   }
// };

/**
 * Cleans up text input to remove all non-numeric characters
 * @param value
 */
export function cleanInput(value: string) {
  return (
    String(value)
      ?.replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1") || ""
  );
}

export const debugLog = (name = "Debug Log", type = "log") => {
  return function (...args: any[]) {
    const color = type === "log" ? "#3ec570" : "#f44336";
    if (process.env.NODE_ENV === "development") {
      console.log(
        `%c${name}`,
        `font-size: 16px; color: ${color}; font-weight: bold;`,
        ...args
      );
    }
  };
};

export const isURI = (value: string) => {
  try {
    new URL(value);
    return true;
  } catch (e) {
    return false;
  }
};

export const resolveNetworkObject: (data: string[]) => NETWORKS_TYPE[] = (
  networks
) => {
  return networks
    .filter((rNetwork) => {
      const nw = NETWORKS.find((main_network) => {
        return rNetwork === main_network.chainId;
      });

      if (
        nw &&
        Number(nw.chainId) === 96 &&
        process.env.REACT_APP_ENVIRONMENT === "production"
      )
        return false;
      return nw;
    })
    .map((resolve_network) => {
      return NETWORKS.find((main_network) => {
        return resolve_network == main_network.chainId;
      });
    }) as NETWORKS_TYPE[];
};

export const resolveCurrentNetworkObject: (
  chainId: string
) => NETWORKS_TYPE | undefined = (chainId) => {
  return NETWORKS.find((main_network) => {
    return chainId === main_network.chainId;
  }) as NETWORKS_TYPE;
};

export const getToken = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const tokenParam = urlParams.get("token");
  return (
    tokenParam ||
    cookie.get(`${process.env.REACT_APP_NODE_ENV}_token`) ||
    cookie.get(`token`)
  );
};

export const randInt = (start = 0, end = 100) =>
  Math.floor(Math.random() * (end - start) + start + 1);

export const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const onBeforeFileUpload =
  (acceptedFiles?: string[]) =>
  (file: any, size = 10) => {
    const accepted = acceptedFiles ?? [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/vnd.ms-powerpoint",
      "application/pdf",
      "text/plain",
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    const isAcceptedFileTpe = accepted.includes(file.type);
    if (!isAcceptedFileTpe) {
      message.error("You can only upload docx, text, csv, excel or pdf files!");
      return isAcceptedFileTpe;
    }
    const isLessThanSize = file.size / 1024 / 1024 < Number(size.toFixed(0));
    if (!isLessThanSize) {
      message.error(`File must smaller than ${size.toFixed(0)}MB!`);
      return isLessThanSize;
    }
    return true;
  };

export const prefixHTTPProtocol = (value: string): string => {
  const regex = /^(?:http|https:\/\/)/i;
  if (regex.test(value)) {
    return value;
  }

  return "https://".concat(value);
};

export const reorder = (
  list: any,
  startIndex: number,
  endIndex: number
): {
  result: Array<any>;
  sourceId: string;
  destinationId: string;
} => {
  const result = Array.from(list);
  const [removed]: any = result.splice(startIndex, 1);
  const [replaced]: any = list.slice(endIndex, 1);
  result.splice(endIndex, 0, removed);
  return {
    result,
    sourceId: removed?._id,
    destinationId: replaced?._id,
  };
};

export const computeEndDate = (startDate: number, phases: any[]): number => {
  if (!phases) phases = [];
  const phaseTotal = phases.reduce((prev, obj) => {
    if (obj) return prev + obj.duration;
    return prev;
  }, 0);
  const phaseMillisec = phaseTotal * 24 * 60 * 60 * 1000;

  return phaseMillisec + startDate * 1000;
};

export const moneyFormat = (value: any, decimal: number = 2) => {
  if (!value) value = 0;
  value = Number(value);
  try {
    const formatter = new Intl.NumberFormat();
    return formatter.format(value.toFixed(decimal));
  } catch (error) {
    return "---";
  }
};

export const copyTextToClipboard = (text: string) => {
  if (typeof window == "undefined") return;
  window.navigator.clipboard.writeText(text);
};

export const getCurrentFullUrl: (address: string) => string = (address) => {
  if (typeof window == "undefined") return "";
  return `${window.location.href.split("#")[0]}?pid=${address}`;
};

export const shorternAddress: (address: string) => string = (address) => {
  return `${address.substring(0, 4)}***${address?.substring(
    address.length - 4
  )}`;
};

export const computeCurrentPhaseEndDate = (
  startDate: number,
  phases: any = []
): number[] => {
  startDate *= 1000;
  let indicatorDate = startDate;
  let index = -1;
  try {
    const _ = phases.findLastIndex((obj: any) => {
      indicatorDate += obj.duration * 24 * 60 * 60 * 1000;
      
      const inRange = startDate <= indicatorDate && indicatorDate >= Date.now();
      if(inRange){
        index++;
      }
      // console.log({currentIndex:++index})
      // console.log({startDate, indicatorDate, ww: Date.now()});
      // console.log({one: startDate <= indicatorDate, two : indicatorDate >= Date.now()});
      // console.log({inRange, indicatorDate, startDate: (new Date(startDate)).toISOString(),  duration: obj.duration, currentEnddate: (new Date(indicatorDate)).toISOString()})
      return inRange;
    });
  } catch (error) {
  }
  // console.log({currentPhaseIndex});
  return [indicatorDate, index];
};

export const getSacContractMetaData = (version: number = 8): any => {
  let data = ContractMetaData.find((d:any)=>d.title ==`SacContractV${version ?? 9}`);
  if(!data) {
    data = ContractMetaData.filter((d:any)=>String(d.title).startsWith(`SacContractV`))[0];
  }
  return data;
}

export const getSacContractTitle = (project?: ProjectNamespace.Project | null): string => {
  if (!project) return `SacContractV9`;
  let data = ContractMetaData.find((d:any)=>d.title ===`SacContractV${project?.contractVersion ?? 9}`);
  
  if(!data) {
    data = ContractMetaData.filter((d:any)=>String(d.title).startsWith(`SacContractV`))[0];
  }
  return data.title;
}