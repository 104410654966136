import { Button, Divider, Form, Typography, App, Spin } from "antd";
import { useContext, useEffect, useState } from "react";
import { FormWrapper } from "../../edit-project/lib/form/index.styled";
import { CheckCircleFilled } from "@ant-design/icons";

import { TOKEN_SYMBOL, moneyFormat } from "../../../_shared";
import { ProjectNamespace } from "../../../_shared/namespaces/project";
import Table, { ColumnsType } from "antd/es/table";
import { useProject } from "../../../hooks";
import { toEther } from "../../../web3/libs/utilities";
import {
  PSAC_TOKEN_ADDRESS,
  SAC_ADMIN_ADDRESS,
  sacToken,
} from "../../../_shared";
import { WalletConnectorContext } from "../../../contexts";
import Web3 from "web3";
import { sacAdminAbi } from "../../../_shared/ABIcode/sac-admin";
const { Text } = Typography;

interface ExtraFeaturesComponentProps {
  project: ProjectNamespace.Project;
  onFinish?: (() => void) | undefined;
  onFinish2?: (() => void) | undefined;
  loading: boolean;
  hideFinishButton?: boolean;
  btnText?: string;
  loadUtils?: (id:string)=>void;
}

export const ExtraFeaturesComponent = (props: ExtraFeaturesComponentProps) => {
  const { onFinish, onFinish2, loadUtils, loading, project,  btnText = "Done", hideFinishButton } = props;

  const { message, notification, modal } = App.useApp();

  const KEY = `@@extra-features`;

  const { handleGet } = useProject({
    key: KEY,
  });

  const [form] = Form.useForm();
  const [features, setFeatures] = useState<ProjectNamespace.FeatureData>();
  const [selectedFeatures, setSelectedFeatures] = useState<String[]>([]);
  const [total, setTotal] = useState(BigInt("0"));
  const [isLoadingPay, setIsLoadingPay] = useState(false);

  const { provider, address } = useContext(WalletConnectorContext);

  const rowSelection = {
    onChange: (
      selectedRowKeys: React.Key[],
      data: ProjectNamespace.Feature[]
    ) => {
      setSelectedFeatures(selectedRowKeys.map((item) => `${item}`));
      setTotal(
        data.reduce((prev, current) => {
          return prev + BigInt(current.price);
        }, BigInt(0))
      );
    },
    getCheckboxProps: (record: ProjectNamespace.Feature) => ({
      name: record.label,
    }),
  };
  const loadFeatures = () => {
   loadUtils?.(`${project.id}`);
    handleGet(`${project.id}/features`, {
      onFinish: (data: any) => {
        setFeatures(data);
        setSelectedFeatures([])
      },
    });
  };
  
  useEffect(() => {
    loadFeatures();
  }, []);

  const columns: ColumnsType<ProjectNamespace.Feature> = [
    {
      title: " ",
      dataIndex: "label",
    },
    {
      title: " ",
      dataIndex: "price",
      render: (price: string) => moneyFormat(toEther(price, 2), 2) + ` ${TOKEN_SYMBOL}`,
    },
    {
      title: <></>,
      dataIndex: "",
    },
    Table.SELECTION_COLUMN,
    {
      title: <div className="flex justify-end"> </div>,
      dataIndex: "",
    },
  ];

  async function hanldeUnlock() {
    setIsLoadingPay(true);
    try {
      const chAllow = await checkContractAllowanceEnough();
      console.log('ALLOWANCECHECK', { chAllow });
      if (!chAllow) {
        const upAllow = await updateAllowance();
        console.log({ upAllow });
      }
      const upActive = await activateFeature();
      console.log({ upActive });
      if (upActive) {
        notification.open({
          message: "Success",
          description: "Payment was Successfully",
        });
        setTimeout(() => {
          loadFeatures();
          onFinish2?.();

        }, 3000);
        
      } else {
        notification.open({
          message: "error",
          description: "Payment Failed",
        });
      }
    } catch (error) {
      console.log({ hanldeUnlockErr: error });
      setIsLoadingPay(false);
    }
    setIsLoadingPay(false);
  }

  const checkContractAllowanceEnough: () => Promise<boolean> = async () => {
    const web3 = new Web3(provider);

    const psacContract = new web3.eth.Contract(
      sacToken as any,
      PSAC_TOKEN_ADDRESS
    );

    try {
      const amountInWei: any = await psacContract.methods["allowance"](
        address,
        SAC_ADMIN_ADDRESS
      ).call();
      console.log("ALLOWANCE", { amountInWei });
      return total < amountInWei;
    } catch (error) {
      console.log({ error });
      return false;
    }
  };
  const activateFeature: () => Promise<boolean> = async () => {
    const web3 = new Web3(provider);
    const contract = new web3.eth.Contract(
      sacAdminAbi as any,
      SAC_ADMIN_ADDRESS
    );
   
    try {
     const args = [
      `${project.id}`,
      project.projectAddress,
      selectedFeatures,
      {
        timestamp: `${features?.timestamp}`,
        price: `${features?.psacPrice}`,
        signature: features?.signature,
       }]
       console.log('PRJECT', SAC_ADMIN_ADDRESS)
      const gasPrice = await web3.eth.getGasPrice();
      const estimatedGas = await contract.methods.activate(...args).estimateGas({
        from: address,
        // gas: web3.utils.toHex(533620),
      });
      const activate = await contract.methods["activate"](

        `${project.id}`,
        `${project.projectAddress}`,
        selectedFeatures,
        {
          timestamp: `${features?.timestamp}`,
          price: total.toString(),
          signature: features?.signature,
        }

      ).send({
        from: address,
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(estimatedGas),
      });
      console.log({ activate });
      return true;
    } catch (error) {
      console.log({ error });
      return false;
    }
  };
  const updateAllowance: () => Promise<boolean> = async () => {
    console.log({ SAC_ADMIN_ADDRESS, address });

    const web3 = new Web3(provider);

    const psacContract = new web3.eth.Contract(
      sacToken as any,
      PSAC_TOKEN_ADDRESS
    );

    // allowance // call
    try {
      const updateAllowance: any = await psacContract.methods["approve"](
        SAC_ADMIN_ADDRESS,
        total.toString()
      ).send({
        from: address,
      });
      console.log({ updateAllowance });
      return true;
    } catch (error) {
      return false;
    }
  };

 

  return (
    <FormWrapper >
      <Text strong className="mx-auto text-xl">
        Unlock Extra features{" "}
      </Text>
      <Form
        form={form}
        name="form_three"
        layout="vertical"
        autoComplete="off"
        requiredMark={"optional"}
        className="justify-self-center "
        // initialValues={initialValue}
      >
        {!features && <Spin className="mx-auto"/>}
        {features && <><Table
        showHeader={false}
        pagination={false}
          loading={loading}
          rowSelection={{
            type: "checkbox",
            selectedRowKeys:selectedFeatures as React.Key[],
            ...rowSelection,
            hideSelectAll: true,
            renderCell(value, record, index, originNode) {
              if (record.enabled)
                return <CheckCircleFilled style={{ color: "green" }} />;
              return originNode;
            },
          }}
          columns={columns}
          dataSource={(features?.features ?? []).map((item) => ({
            ...item,
            key: item.id,
          }))}
          footer={() => {
            return (
              <div className="w-full flex justify-between">
                <Text strong>Total</Text>
                <Text strong>
                  {moneyFormat(toEther(total.toString(), 2), 2)}  {TOKEN_SYMBOL}
                </Text>
                <Button
                  loading={isLoadingPay}
                  disabled={selectedFeatures.length == 0}
                  className="shadow-none"
                  type="primary"
                  onClick={hanldeUnlock}
                >
                  <Text strong>Pay to Unlock</Text>
                </Button>
              </div>
            );
          }}
        />

        {!hideFinishButton && <Divider style={{ marginTop: 40 }} />}

        {!hideFinishButton && <Form.Item>
          <Button
            disabled={false}
            loading={loading}
            size="large"
            className="next-btn ml-auto"
            type="primary"
            htmlType="button"
            onClick={() => {
              onFinish?.();
            }}
          >
            {btnText}
          </Button>
        </Form.Item>}
        </>}
      </Form>
    </FormWrapper>
  );
};
