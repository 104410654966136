import {  Col, Divider,  Row,  Typography } from "antd";
import { moneyFormat } from "../../../../_shared";
import { ProjectNamespace } from "../../../../_shared/namespaces/project";
import { CoverContainer } from "./index.styled";
const { Text } = Typography;

interface SacrificeAddressProps {
  project?: ProjectNamespace.Project;
  sacAddress?: string
  sacrificePhaseDay?: string
}
export const SacrificeAddress = (props: SacrificeAddressProps) => {
    const {sacAddress, project:_project, sacrificePhaseDay} = props;
    const project = _project??{} as ProjectNamespace.Project;
    const phases = project.phases??[];
    const volumeBonus = project.volumeBonus??[];
    let lastDay = 0;
    const renderPhases = phases.map((phase, index:number)=>{
      const startDay = lastDay+1;
      const endDay = lastDay+phase.duration;
      
      const inRange = Number(sacrificePhaseDay) >= startDay && Number(sacrificePhaseDay) <= endDay;
      lastDay =  endDay;
      return <Row key={index} justify="space-between">
                <Col>
                  <Text className={inRange?'title-text':''}>{startDay} - {endDay}</Text>
                </Col>
                <Col>
                  <Text className={inRange?'title-text':''}>{moneyFormat(phase.pointAmount)}</Text>
                </Col>
              </Row>

    })

    const renderVolumeBonuses = volumeBonus.map((bonus, index:number)=>{
      
      return  <Row justify="space-between" key={index}>
                <Col>
                  <Text>${moneyFormat(bonus.contributionAmount)}</Text>
                </Col>
                <Col>
                  <Text >{bonus.percentage}%</Text>
                </Col>
              </Row>

    })
    return (
          <CoverContainer  id="sacrifice">
              <Row justify="space-between">
                <Col>
                  <Text className="disable">Contribution Address</Text>
                </Col>
                <Col>
                  <Text>{sacAddress??'...'}</Text>
                </Col>
              </Row>
              <Divider />
              <Row justify="space-between">
                <Col>
                  <Text className="title-text">Phases</Text>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col>
                  <Text className="disable">Days</Text>
                </Col>
                <Col>
                  <Text className="disable">Point / BUSD</Text>
                </Col>
              </Row>
              {renderPhases}
              <Divider />
              <Row justify="space-between">
                <Col>
                  <Text className="title-text">Volume Reward</Text>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col>
                  <Text className="disable">USD Amount</Text>
                </Col>
                <Col>
                  <Text className="disable">Bonus</Text>
                </Col>
              </Row>
              {renderVolumeBonuses}
                         
          </CoverContainer>
    );
};