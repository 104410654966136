import {  DeleteOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox,  DatePicker, Divider, Form, Input, InputNumber, Space, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import { FormWrapper } from "../index.styled";
const { Text } = Typography;


interface FormTwoComponentProps  {
    // length: number;
    // currentIndex: number;
    loading: boolean;
    onFinish?: ((values: any) => void) | undefined
    handlePrevious?: (() => void)
}

export const FormTwoComponent = (props: FormTwoComponentProps) => {
    const {onFinish, handlePrevious, loading} = props;

    const offset = dayjs().utcOffset();
    const utc = `${offset>-1?'+':''}${offset/60}`

    
    return (
            <FormWrapper>
                <Form
                    name="form_two"
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                    initialValues={{ phases: [""] }}
                    requiredMark={'optional'}

                    >
                    <Form.Item>
                        <Space>
                            <span className="ant-form-text">Phases</span>
                            <Tooltip title="Enter the number of points that each donor will get for each dollar contributed and the time period (in days) that rate will be applied. Typically reduce the rate over time to compensate earlier donors. eg. 1000 points/USD for the first 5 day phase, and 900 points/USD for the next 5 day phase">
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Space>
                        
                    </Form.Item>
                    <Form.List
                        name="phases"
                        rules={[
                        {
                            validator: async (_, links) => {
                            if (!links || links.length < 1) {
                                return Promise.reject(new Error('At least 1 Phase'));
                            }
                            },
                        },
                        ]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            
                        <>
                            {fields.map((field, index) => (
                                <Space key={field.key +index} className="social-media-items" align="center" >
                                    <Form.Item
                                        {...field}
                                        label="Points per USD Contributed (Decimal not supportted)"
                                        key={'pointAmount' +index}
                                        name={[field.name, 'pointAmount']}
                                        // requiredMark={'optional'}
                                        rules={[{ required: true, message: 'Please input rate' }]}
                                    >
                                        <InputNumber min={0} 
                                        step={1}
                                            parser={(value => {
                                                if(!value) return 0;
                                                return Math.floor(Number(value));
                                            })}

                                         placeholder="e.g 200" />
                                    </Form.Item>
                                    <Form.Item
                                        {...field}
                                        label="Duration (Days)"
                                        key={'duration' +index}
                                        name={[field.name, 'duration']}
                                        // requiredMark={'optional'}
                                        rules={[{ required: true, message: 'Please input duration!' }]}
                                    >
                                        <InputNumber 
                                            min={0}
                                            parser={(value => {
                                                if(!value) return 0;
                                                return Math.floor(Number(value));
                                            })}
                                            placeholder="Enter No of Days" />
                                    </Form.Item>
                                    
                    
                                    {fields.length > 1 ? (
                                        <DeleteOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                        />
                                        ) : null}
                                </Space>
                            ))}
                            <Form.Item>
                                <Button
                                    
                                    type="text"
                                    onClick={() => add()}
                                    style={{ marginRight: 'auto' }}
                                    icon={<PlusOutlined />}
                                >
                                    Add another phase
                                </Button>
                            </Form.Item>
                            <div>
                                <Form.ErrorList errors={errors} />
                            </div>
                        </>
                        )}
                    </Form.List>
                    
                    <Form.Item
                        label={`Fundraiser Start Date/Time (UTC ${utc})`}
                        name="startDayTime"
                        // requiredMark={'optional'}
                        rules={[{ required: true, message: 'Please select date!' }]}
                    >
                        <DatePicker 
                            showNow={false}
                            disabledDate={current => {
                                // Can not select days before today and today
                                return current && current < dayjs().startOf('day');
                              }}
                            showTime/>
                    </Form.Item>

                    
                    {/* <Space>
                            <span className="ant-form-text" style={{whiteSpace: 'nowrap'}}>Referral Reward</span>
                            <Tooltip title="Issue bonuses to your upline (referral link creator) and downline (link taker)">
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Space>
                    <Form.Item
                        className="label-with-tip"
                        label={<Space>
                            <Text>Percentage Upline Bonus</Text>
                            <Tooltip title="This is the percentage of the downlines donations an upline will get">
                                <InfoCircleOutlined />
                            </Tooltip>

                        </Space>}
                        name="referralBonusPercent"
                        
                        rules={[
                                { required: false, message: 'Please input percentage!', },
                                {
                                    message: 'Value must be 0 - 100!',
                                    validator: async (_, value) => {
                                        if (!value || value < 0  || value > 100) {
                                            return Promise.reject(new Error('Value must be 0 - 100'));
                                        }
                                    },
                                }
                            ]}
                    >
                        <Input  type={"number"} max={100} min={0} placeholder="e.g 5" suffix="%" />
                    </Form.Item>

                    <Form.Item
                        className="label-with-tip"
                        label={<Space>
                            <Text>Percentage Downline Reward</Text>
                            <Tooltip title="This is the extra percentage of points that a downline will get for using an uplines address">
                                <InfoCircleOutlined />
                            </Tooltip>
                            
                        </Space>}
                        
                        name="refereeBonusPercent"
                        rules={[
                            { required: false, message: 'Please input percentage!', },
                            {
                                message: 'Value must be 0 - 100!',
                                validator: async (_, value) => {
                                    if (!value || value < 0  || value > 100) {
                                        return Promise.reject(new Error('Value must be 0 - 100'));
                                    }
                                },
                            }
                        ]}
                    >
                        <Input  type={"number"} max={100} min={0} placeholder="e.g 5" suffix="%" />
                    </Form.Item>

                    <Form.Item
                        name="uplineMustBeSacrificer"
                        // requiredMark={'optional'}
                        valuePropName="checked"
                    >
                        <Checkbox defaultChecked={false}>Upline must be a previous donor</Checkbox>
                    </Form.Item>

                    <Form.Item
                        className="label-with-tip"
                        label={<Space>
                            <Text>Minimum Contribution Amount</Text>
                            <Tooltip title="This is the minimum amount in dollar value a sacfificer can donate per transaction">
                                <InfoCircleOutlined />
                            </Tooltip>

                        </Space>}
                        name="minContributionAmount"
                        // requiredMark={'optional'}
                        rules={[{ required: true, message: 'Please input amount!',  },
                                {
                                    validator: async (_, value) => {
                                        console.log(`Please input amount must be 0 or more!`, value)
                                        if (!value || value < 0) {
                                            return Promise.reject(new Error('Please input amount must be 0 or more'));
                                        }
                                    },
                                }]}
                        
                    >
                        <Input type={"number"} min="1" placeholder="Enter Amount" suffix="USD" />
                    </Form.Item> */}

                    <Divider style={{marginTop: 40}} />

                    

                    <Form.Item>
                        <Button onClick={handlePrevious} size="large" className="prev-btn" htmlType="button">
                            Previous
                        </Button>
                        <Button loading={loading} size="large" className="finish-btn" type="primary" htmlType="submit">
                            Next 
                        </Button>
                    </Form.Item>
                </Form>
            </FormWrapper>
    );
  };    