import { Button,  Form, Typography, InputNumber } from "antd";
import { ProjectNamespace } from "../../../../_shared/namespaces/project";
import { PSacBonusWrapper, FormWrapper } from "./index.styled";
import { TOKEN_SYMBOL } from "../../../../_shared";
const {Text} = Typography;

interface PSacBonusComponentProps{
    project: ProjectNamespace.Project;
    onFinish? : (data?:any)=>void;
    loading?: boolean
}
export const PSacBonusComponent = (props: PSacBonusComponentProps) => {
    const {onFinish, loading, project} = props;

    return (
          <PSacBonusWrapper className="pt-10">
               <div className="hint" >
            <Text>
              Issue a percentage bonus to {TOKEN_SYMBOL} community members. Provide the maximum percentage a {TOKEN_SYMBOL} staker will earn below
            </Text>
            </div>
            
            {project.createdAt && <FormWrapper
                name="distribution_token"
                layout="vertical"
                initialValues={{ psacBonusPercent: project.psacBonusPercent }}
                onFinish={onFinish}
                autoComplete="off"
                requiredMark={'optional'}
                >
                <Form.Item
                    label={`Maximum ${TOKEN_SYMBOL} Community Bonus (%)`}
                    name="psacBonusPercent"
                    
                    rules={[{ required: true, message: 'Please input a percentage!' }]}
                >
                    <InputNumber placeholder="Enter Bonus" />
                </Form.Item>
                <Form.Item >
                    <Button loading={loading} className="float-right" type="primary" htmlType="submit">
                      Save
                    </Button>
                </Form.Item>
            </FormWrapper>}
          </PSacBonusWrapper>
    );
};