import { Button, Divider, Space, Tabs, Typography } from "antd";
import { useEffect, useState } from "react";
import { ProjectNamespace } from "../../_shared/namespaces/project";
import { ConnectButton } from "../project/connect-button";
import { EditProjectWrapper } from "./index.styled";
import {
  FormOneComponent,
  FormTwoComponent,
  FormThreeComponent,
} from "./lib/form";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { StepComponent } from "./lib/steps";
import { ExtraFeaturesComponent } from "../utils/extra-features";
import { useSearchParams } from "react-router-dom";
const { Text } = Typography;
const { TabPane } = Tabs;



interface EditProjectComponentProps {
  project: ProjectNamespace.Project;
  onComplete?: ((values?: any) => void) | undefined;
  handleBackArrow: (values?: any) => void;
  _OnSubmitFormOne: (values?: any) => void;
  _OnSubmitFormTwo: (values?: any) => void;
  loading: boolean;
  loadingConnect: boolean;
  address?: string;
  handleConnect: () => void;
  totalPhaseDays: number;
}

export const EditProjectComponent = (props: EditProjectComponentProps) => {
  const {
    onComplete,
    handleBackArrow,
    _OnSubmitFormOne,
    _OnSubmitFormTwo,
    project,
    loadingConnect,
    loading,
    address,
    totalPhaseDays,
    handleConnect,
  } = props;
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab"); // "text"
  const [currentIndex, setCurrentIndex] = useState(0);
  const onSubmitFormOne = (data: any) => {
    _OnSubmitFormOne(data);
  };

  const onSubmitFormTwo = (data: any) => {
    _OnSubmitFormTwo(data);
  };

  const onSubmitFormComplete = (data: any) => {
    if (onComplete) onComplete(data);
  };

  const handleBackButton = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      handleBackArrow();
    }
  };
  const handlePrevButton = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const handleNextButton = () => {
    if (currentIndex < 3) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const onSubmitFormFour = () => {
    handleBackArrow();
  };

  useEffect(() => {
    console.log({tab})
    if(tab == "extra"){
      setCurrentIndex(3)
    }
  }, [tab]);
  return (
    <EditProjectWrapper className="mt-10">
      <Space className="main-container max-w-[700px]" direction="vertical">
        <Space className="header-row mb-5" align="center">
          <Button
            className="back"
            onClick={handleBackButton}
            type="ghost"
            icon={
              <ArrowLeftOutlined/>
            }
          />
          <Space>
            {currentIndex ? (
              <Button type="primary" shape="round" onClick={handlePrevButton}>
                Previous
              </Button>
            ) : (
              ""
            )}
            {currentIndex < 3 ? (
              <Button type="primary" shape="round" onClick={handleNextButton}>
                Next
              </Button>
            ) : (
              ""
            )}
          </Space>
        </Space>
        <Space className="title-row" align="center">
          <Text className="title-text">Update Project</Text>
          <StepComponent length={3} currentIndex={currentIndex} />
        </Space>
        <Divider className="header-divider" />
        {address && (
          <Tabs activeKey={currentIndex + ""} renderTabBar={() => <></>}>
            <TabPane tab={null} key="0">
              <Space className="form-container">
                <FormOneComponent
                  project={project}
                  loading={loading}
                  onFinish={onSubmitFormOne}
                  
                />
              </Space>
            </TabPane>
            <TabPane tab={null} key="1">
              <Space className="form-container">
                <FormTwoComponent
                  project={project}
                  loading={loading}
                  onFinish={onSubmitFormTwo}
                  handlePrevious={handleBackButton}
                />
              </Space>
            </TabPane>
            {/* <TabPane tab={null} key="2">
              <Space className="form-container">
                <FormThreeComponent
                  project={project}
                  onFinish={onSubmitFormComplete}
                  handlePrevious={handleBackButton}
                  loading={loading}
                  totalPhaseDays={totalPhaseDays}
                />
              </Space>
            </TabPane> */}
            <TabPane tab={null} key="2">
              <Space className="form-container">
                <ExtraFeaturesComponent
                  project={project}
                  onFinish={onSubmitFormFour}
                  loading={loading}
                />
              </Space>
            </TabPane>
          </Tabs>
        )}
        {!address && (
          <ConnectButton
            loading={loadingConnect}
            handleConnect={handleConnect}
          />
        )}
      </Space>
    </EditProjectWrapper>
  );
};
