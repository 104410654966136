

import { RightCircleFilled } from "@ant-design/icons";
import { message, Spin, Tabs, } from "antd";
import { useContext, useEffect, useState } from "react";
import { ViewProjectWrapper } from "./index.styled";
import { AddTokenModal } from "./libs/modal/add-token";
import { ManageNetworkComponent } from "./libs/manage-network";
import { ManageTokenLoadedComponent } from "./libs/manage-token-loaded";
import { TopDetailComponent } from "./libs/top-detail";
import { ShareModal } from "./libs/modal/share";
import { WhitelistComponent } from "./libs/whitelist";
import { NewGroupModal } from "./libs/modal/new-group";
import { ManageWhitelistModal } from "./libs/modal/manage-whitelist";
import { VolumeBonusComponent } from "./libs/volume-bonus";
import { NewVolumeBonusModal } from "./libs/modal/new-volume-bonus";
import { DistributeTokenComponent } from "./libs/distribute-token";
import { ClaimPercentageModal } from "./libs/modal/claim-percentage";
import { ProjectNamespace } from "../../_shared/namespaces/project";
import {  NETWORKS_TYPE, TOKEN_SYMBOL } from "../../_shared/constants";
import { WhitelistNamespace } from "../../_shared/namespaces/whitelist";
import { AcceptedToken } from "../../_shared/utils/type";
import { copyToClipboard, moneyFormat } from "../../_shared";
import { toEther } from "../../web3/libs/utilities";
import { PSacBonusComponent } from "./libs/psac-bonus";
import { AdminBonusComponent } from "./libs/admin-bonus";
import { NewAdminBonusModal } from "./libs/modal/new-admin-bonus";
import { AdminNamespace } from "../../_shared/namespaces/admin";
import { useNavigate } from "react-router-dom";

import { WalletConnectorContext } from "../../contexts";
// const { Text } = Typography;
const { TabPane } = Tabs;

interface LoadingState {
  loading: boolean
  loadingDeploy: boolean
  loadingAddress: boolean
  loadingWhitelist:boolean
  loadingAddToken:boolean
  uiLoaders: Record<string, boolean>
}

interface FunctionsState {
  handleBackArrow: ((values?: any) => void)
  handleNetworkSelect: ((data?: any) => void)
  handleSelectAvailableNetwork: ((any?: any) => void)
  handleDeploy: ((data?: any) => void)
  handleNewGroupSubmit: ((data: any) => void)
  handleManageGroupSubmit: ((data: any) => void)
  handleManageVolumeBonus: ((data: any, type:string) => void)
  handleManageAdminBonus: ((data: any, type:string) => void)
  handleNewTokenSubmit: ((data: any) => void)
  handleRemoveTokenSubmit: ((data: any) => void) 
  handleWidthdrawToken: ((data: any) => void) 
  handleNewVolumeBonus: ((data: any) => void)
  initWhitelistComponent: (() => void)
  setSelectedGroup: ((data: any) => void)
  handleShowTokenScreen: ((data?: any) => void)
  handleUpdatePsac: ((data?: any) => void)
}

interface DataState{
  project: ProjectNamespace.Project;
  
  currentNetwork?: NETWORKS_TYPE
  switchingNetwork?: NETWORKS_TYPE
  
  sacAddress?: string
  projectInfo?: any
  projectOwners?: string
  fees?: {
    currentFeeFlat: string,
    currentFeePercentx100: string,
  }
  allWhitelists: WhitelistNamespace.Whitelist[];
  selectedGroup?: WhitelistNamespace.Whitelist;
  selectedAdminBonus?: AdminNamespace.AdminBonus;
  selectedVolumeBonus?:{contributionAmount: string; points:string;}
  acceptedTokens?: AcceptedToken[]
  showTokenScreen: boolean
  selectedTokenAddress?: string
  adminBonuses: AdminNamespace.AdminBonus[]
  usdDecimals: string
  isOwner: boolean
}
interface ViewProjectComponentProps {
  fns: FunctionsState
  loadings: LoadingState
  dataStates: DataState
  
}

export const ViewProjectComponent = (props: ViewProjectComponentProps) => {
  const {dataStates, loadings, fns} = props;
  const {address, chainId, signedAddress,  loading: loadingConnect} = useContext(WalletConnectorContext);
    const {project, sacAddress, allWhitelists, currentNetwork, 
      selectedGroup, selectedVolumeBonus,
     projectOwners, projectInfo, acceptedTokens,showTokenScreen,
     selectedTokenAddress, adminBonuses, selectedAdminBonus,
      fees, usdDecimals, switchingNetwork, isOwner} = dataStates;
  const {loadingDeploy, loadingWhitelist, loadingAddToken, loading, loadingAddress, uiLoaders} = loadings;
  const {setSelectedGroup, handleRemoveTokenSubmit, handleWidthdrawToken,handleNewVolumeBonus,
    handleNetworkSelect, handleSelectAvailableNetwork, handleDeploy,
    initWhitelistComponent,handleNewGroupSubmit,handleManageGroupSubmit,
    handleManageVolumeBonus, handleManageAdminBonus, handleShowTokenScreen,handleUpdatePsac,
    handleNewTokenSubmit, handleBackArrow} = fns;
    let navigate = useNavigate();
  const [distributedTokenLoaded, setDistributedTokenLoaded] = useState(false);
  const [showAddTokenModal, setShowAddTokenModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showNewGroup, setShowNewGroup] = useState(false);
  const [showManageGroup, setShowManageGroup] = useState(false);
  const [showNewVolumeBalance, setShowNewVolumeBalance] = useState(false);
  const [showNewAdminBalance, setShowNewAdminBalance] = useState(false);
  const [showDistributionToken, setShowDistributionToken] = useState(false);
  
  const handleAddNewToken = ()=>{
    setShowAddTokenModal(old=>!old)
  }
  const handleNewGroup = ()=>{
    setShowNewGroup(old=>!old)
  }
  const handleManageGroup = (data: any)=>{
     setSelectedGroup(data)
    // setShowManageGroup(old=>!old)
  }

  useEffect(()=> {
    setShowManageGroup(old=>!old);
  }, [selectedGroup])

  const _handleNewVolumeBonusToggle = (data:any)=>{
    setShowNewVolumeBalance(old=>!old)
  }
  useEffect(() => {
    
    if((!loading && !address) || (address && project.user && `${address}`.toLowerCase() !== project.user)){
      navigate("/projects")
    }
  }, [address, project.user, loadingConnect,]);

  

  const handleStartDistribution = ()=>{
    setDistributedTokenLoaded(old=>!old)
  }

  const handleClaimPercentage = ()=>{
    setShowDistributionToken(old=>!old)
  }

  const handleShareButton = ()=>{
    setShowShareModal(old=>!old)
  }
  const onClose = ()=>{
    setShowAddTokenModal(false); 
    setShowShareModal(false); 
    setShowNewGroup(false);
    setShowManageGroup(false);
    setShowNewVolumeBalance(false);
    setShowNewAdminBalance(false);
    setShowDistributionToken( false);
  }

  
  const onFinish = (data:any)=>{
    handleNewTokenSubmit(data);
    setShowAddTokenModal(false);
  }
  const onFinishNewGroup = (data:any)=>{
    handleNewGroupSubmit(data)
    setShowNewGroup(false);
  }
  let totalSacrificedValue = '0';
  try {
    totalSacrificedValue = moneyFormat(toEther(project.totalSacrificedValue??'0', 4));
  } catch (error) {
    
  }
  const detailList  = [
    {
      key:"Contribution Address",
      value:(sacAddress??project?.projectAddress)
    },
    {
      key:"Total Sacrificed Value",
      value:`$ ${totalSacrificedValue}`
    }
  ]
  const [listData, _] = useState([
    {
      name: 'USDT Sacrificed',
      price: '2,230 USDT',
      bonus: '5%'
    },
    {
      name: 'ETH Sacrificed',
      price: '2.3423 ETH',
      bonus: '15%'
    },
    {
      name: 'USDC Sacrificed',
      price: '0 USDC',
      bonus: '70%'
    }
  ]);

  const networks = project.networks??[];
  console.log({"project.networks": project.networks})
  return (
        <ViewProjectWrapper>
          <TopDetailComponent 
            handleShareButton={handleShareButton} 
            handleBackButton={handleBackArrow} 
            loadingAddress={loadingAddress}
            detailList={detailList}
            project={project}
            isOwner={isOwner}
            />
            <div style={{width: '300px !important'}}>
          <Tabs tabPosition={'left'} defaultActiveKey="0"  style={{minWidth: '300px !important', left:0, overflowX: 'scroll'}} moreIcon={<RightCircleFilled />}>
              <TabPane tab="Manage Networks" key="0" style={{minWidth: 280}}>
                {!showTokenScreen?<ManageNetworkComponent
                  switchingNetwork={switchingNetwork}
                  project={project}
                  handleManageToken={handleShowTokenScreen} 
                  projectOwners={projectOwners}
                  networks={networks}
                  loadingDeploy={loadingDeploy}
                  handleDeploy={handleDeploy}
                  currentNetwork={currentNetwork}
                  handleSelectAvailableNetwork={handleSelectAvailableNetwork}
                  loadingNetworkSelect={loading}
                  fees={fees}
                  usdDecimals = {usdDecimals}
                  handleNetworkSelect={handleNetworkSelect}/>:
                  <ManageTokenLoadedComponent
                    handleBackButton={handleShowTokenScreen} 
                    projectOwners={projectOwners}
                    networks={networks}
                    loadingNetworkSelect={loading}
                    loadingAddToken={loadingAddToken}
                    loadingWithdrawToken={uiLoaders['withdraw']}
                    handleAddNewToken={handleAddNewToken}
                    handleRemoveTokenSubmit={handleRemoveTokenSubmit}
                    handleWidthdrawToken={handleWidthdrawToken}
                    selectedTokenAddress={selectedTokenAddress}
                    currentNetwork={currentNetwork}
                    acceptedTokens={acceptedTokens}
                    totalSacUSD={projectInfo?.totalContributed??''}
                  listData={listData} />
                  }
              </TabPane>
              <TabPane tab="Whitelist Reward" key="2">
                <WhitelistComponent
                    handleNewGroup={handleNewGroup}
                    handleManageGroup={handleManageGroup}
                    initComponent={initWhitelistComponent}
                    loading={loadingWhitelist}
                    allWhitelists={allWhitelists} />
              </TabPane>
              <TabPane tab="Volume Reward" key="3">
                <VolumeBonusComponent
                    handleNewVolumeBonus={_handleNewVolumeBonusToggle}
                    handleManageVolumeBonus={handleManageVolumeBonus}
                    loading={loading}
                    listData={(project.volumeBonus??[]) as any} />
              </TabPane>
              <TabPane tab="Admin Reward" key="3.5">
                <AdminBonusComponent
                    handletoggleAdminBonusModal={()=>{
                      setShowNewAdminBalance(old=>!old)
                    }}
                    handleManageAdminBonus={handleManageAdminBonus}
                    selectedAdminBonus={selectedAdminBonus}
                    loading={uiLoaders['addAdminBonus'] || uiLoaders['cancelAdminBonus']}
                    adminBonuses={adminBonuses??[]} />
              </TabPane>
          <TabPane tab={`${TOKEN_SYMBOL} Community Bonus`} key="4">
                  <PSacBonusComponent project={project} loading={loading}  onFinish={handleUpdatePsac}/>
              </TabPane>
              <TabPane tab="Distribute Tokens" key="5">
                  <DistributeTokenComponent distributedTokenLoaded={distributedTokenLoaded} handleClaimPercentage={handleClaimPercentage} onFinish={handleStartDistribution}/>
              </TabPane>
          </Tabs>
          </div>
          <AddTokenModal currentNetwork={currentNetwork as NETWORKS_TYPE} visible={showAddTokenModal} onFinish={onFinish} onClose={onClose}/>
          <ShareModal 
            onCopied={()=>{
              copyToClipboard(`${window.location.origin}/sac/${project.id??''}`)
              message.success('copied'); 
            }} 
            visible={showShareModal}
            text={`${window.location.origin}/sac/${project.id??''}`}
            onClose={onClose}/>
          <NewGroupModal visible={showNewGroup} onFinish={onFinishNewGroup} onClose={onClose}/>
          <ManageWhitelistModal visible={showManageGroup} onFinish={(data:any)=>{
              handleManageGroupSubmit(data)
              setShowManageGroup(false)
            }} onClose={onClose}
            selectedGroup={selectedGroup}
          />
          <NewVolumeBonusModal 
            visible={showNewVolumeBalance} 
            selectedVolumeBonus={selectedVolumeBonus}
            loading={loading}
            onFinish= {(data:any)=>{
                handleNewVolumeBonus(data)
                setShowNewVolumeBalance(false)
                }} 
            onClose={onClose}/>

          <NewAdminBonusModal 
            visible={showNewAdminBalance} 
            selectedAdminBonus={undefined}
            loading={uiLoaders['addAdminBonus']}
            onFinish= {(data:any)=>{
                handleManageAdminBonus(data, 'create')
                setShowNewAdminBalance(false)
                }} 
            onClose={onClose}/>
          <ClaimPercentageModal project={project} visible={showDistributionToken} onClose={onClose}/>
        </ViewProjectWrapper>
  );
};