import {
  DeleteOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Button,
  Divider,
  Form,
  Input,
  message,
  notification,
  Select,
  Space,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import { RcFile } from "antd/lib/upload";
import { useMemo, useState } from "react";
import {
  CATEGORIES,
  PRODUCTSTAGES,
  REACT_APP_BASEURL,
  SOCIALS,
} from "../../../../../_shared";
import { FormWrapper } from "../index.styled";
const { Option } = Select;

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

interface FormOneComponentProps {
  // length: number;
  // currentIndex: number;
  onFinish?: ((values: any) => void) | undefined;
}

export const FormOneComponent = (props: FormOneComponentProps) => {
  const { onFinish } = props;
  const [loading, setLoading] = useState(false);
  const [loadingBanner, setLoadingBanner] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [bannerImageUrl, setBannerImageUrl] = useState<string>();
  const [form] = Form.useForm();
  const introVideo = Form.useWatch<string>("introVideo", form);

  const [catOptions, setCatOptions] = useState<{ value: string }[]>(
    CATEGORIES.map((catValue) => ({
      value: catValue,
    }))
  );
  const [altCatOptions, setAltCatOptions] = useState<{ value: string }[]>(
    CATEGORIES.map((catValue) => ({
      value: catValue,
    }))
  );

  const embededUrl = useMemo(() => {
    if (!introVideo) return "";
    const splitted = introVideo.split("youtube.com/watch?v=");
    const splittedShort = introVideo.split("youtube.com/shorts/");
    if (splittedShort.length > splitted.length) {
      return (
        "https://www.youtube.com/embed/" +
        splittedShort[splittedShort.length - 1]
      );
    }
    return "https://www.youtube.com/embed/" + splitted[splitted.length - 1];
  }, [introVideo]);

  const beforeUpload = (file: RcFile) => {
    setLoading(true);
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif";
    if (!isJpgOrPng) {
      setImageUrl("");
      notification.error({
        key: `isJpgOrPng`,
        message: "You can only upload JPG/PNG file",
        placement: "bottomLeft",
      });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.error({
        key: `isLt2M`,
        message: "Image must smaller than 2MB",
        placement: "bottomLeft",
      });
      setImageUrl("");
    }
    return isJpgOrPng && isLt2M;
  };

  const beforeBannerUpload = (file: RcFile) => {
    setLoadingBanner(true);
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif";
    if (!isJpgOrPng) {
      setBannerImageUrl("");
      notification.error({
        key: `isJpgOrPng`,
        message: "You can only upload JPG/PNG file",
        placement: "bottomLeft",
      });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.error({
        key: `isLt2M`,
        message: "Image must smaller than 2MB",
        placement: "bottomLeft",
      });
      setBannerImageUrl("");
    }
    return isJpgOrPng && isLt2M;
  };

  // useEffect(()=> {
  //     console.log('imag', imageUrl);
  //     form.setFieldsValue({ logo: imageUrl })}, [imageUrl]);

  return (
    <FormWrapper>
      <Form
        form={form}
        name="form_one"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{ socials: [{ name: "telegram", url: "" }] }}
        requiredMark={false}
      >
        <Form.Item
          label="Project Title"
          name="title"
          // requiredMark={'optional'}
          rules={[
            { required: true, message: "Please input your project title!" },
          ]}
        >
          <Input placeholder="e.g GameBoy" />
        </Form.Item>
        <Form.Item
          name="tagline"
          label="Tagline"
          // requiredMark={'optional'}
          rules={[{ required: true, message: "Please input tagline" }]}
        >
          <Input
            placeholder="e.g First dex on the planet"
            showCount
            maxLength={30}
          />
        </Form.Item>

        <Form.Item
          label="Website URL (Must begin with http:// or https://)"
          name="website"
          // requiredMark={'optional'}
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              //   type: "url",
              message: "Invalid url",
              pattern: new RegExp(
                "(https://www.|http://www.|https://|http://)?([a-zA-Z0-9]{2,}.[a-zA-Z]{2,})+"
              ),
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          label="Product Stage"
          name="productStage"
          // requiredMark={'optional'}
          validateTrigger="onBlur"
          rules={[
            { required: true, message: "Please select a project stage!" },
          ]}
        >
          <Select placeholder="Select a project stage" allowClear>
            {PRODUCTSTAGES.map((item, index) => {
              return (
                <Option key={index} value={item}>
                  {item}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="Category"
          name="category"
          // requiredMark={'optional'}
          validateTrigger="onBlur"
          rules={[{ required: true, message: "Please select a category!" }]}
        >
          <AutoComplete
            options={catOptions}
            // onSelect={onSelect}
            onSearch={(text) => {
              if (text) {
                setCatOptions(
                  CATEGORIES.filter((fV) =>
                    fV.toLowerCase().includes(text.toLowerCase())
                  ).map((catValue) => ({
                    value: catValue,
                  }))
                );
              } else {
                setCatOptions(
                  CATEGORIES.map((catValue) => ({
                    value: catValue,
                  }))
                );
              }
            }}
            placeholder="Select a category"
          />
        </Form.Item>

        <Form.Item
          label="Alt Category"
          name="subCategory"
          // requiredMark={'optional'}
          validateTrigger="onBlur"
          rules={[
            { required: true, message: "Please select an alternate category!" },
          ]}
        >
          <AutoComplete
            options={altCatOptions}
            // onSelect={onSelect}
            onSearch={(text) => {
              if (text) {
                setAltCatOptions(
                  CATEGORIES.filter((fV) =>
                    fV.toLowerCase().includes(text.toLowerCase())
                  ).map((catValue) => ({
                    value: catValue,
                  }))
                );
              } else {
                setAltCatOptions(
                  CATEGORIES.map((catValue) => ({
                    value: catValue,
                  }))
                );
              }
            }}
            placeholder="Select a category"
          />
        </Form.Item>

        <Form.Item
          name="logo"
          label="Logo"
          validateStatus="validating"
          // requiredMark={'optional'}
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              type: "url",
              message: "Please upload your project logo!",
            },
          ]}
        >
          <ImgCrop rotationSlider>
            <Upload
              name="file"
              //disabled={loading}
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={`${REACT_APP_BASEURL}/media`}
              beforeUpload={beforeUpload}
              onChange={(info) => {
                console.log({ info });
                if (info?.file?.status === "done") {
                  // Get this url from response in real world.
                  getBase64(info?.file?.originFileObj as RcFile, (url) => {
                    setLoading(false);
                    setImageUrl(url);
                  });
                }
                if (info?.file?.status === "uploading") {
                  setLoading(true);
                }
                const url =
                  info?.file?.response?.data?.url ??
                  info?.file?.response?.data?.file?.url;
                form.setFieldsValue({ logo: url });
              }}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ height: "100%" }} />
              ) : (
                <div>
                  {loading ? <LoadingOutlined /> : <PlusOutlined />}
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>

        <Form.Item
          name="spotlightImage"
          label="Banner"
          validateStatus="validating"
          // requiredMark={'optional'}
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              type: "url",
              message: "Please upload your project Banner!",
            },
          ]}
        >
          <ImgCrop rotationSlider aspect={4 / 1}>
            <Upload
              name="file"
              //disabled={loading}
              listType="picture-card"
              className="avatar-uploader banner"
              showUploadList={false}
              action={`${REACT_APP_BASEURL}/media`}
              beforeUpload={beforeBannerUpload}
              onChange={(info) => {
                if (info?.file?.status === "done") {
                  // Get this url from response in real world.
                  getBase64(info?.file?.originFileObj as RcFile, (url) => {
                    setLoadingBanner(false);
                    setBannerImageUrl(url);
                  });
                }
                if (info?.file?.status === "uploading") {
                  setLoadingBanner(true);
                }
                const url =
                  info?.file?.response?.data?.url ??
                  info?.file?.response?.data?.file?.url;
                form.setFieldsValue({ spotlightImage: url });
              }}
            >
              {bannerImageUrl ? (
                <img
                  src={bannerImageUrl}
                  alt="avatar"
                  style={{ height: "100%" }}
                />
              ) : (
                <div>
                  {loadingBanner ? <LoadingOutlined /> : <PlusOutlined />}
                  <div style={{ marginTop: 8 }}>Upload Banner</div>
                </div>
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          // requiredMark={'optional'}
          rules={[{ required: true, message: "Please input Description" }]}
        >
          <Input.TextArea rows={4} showCount maxLength={700} />
        </Form.Item>

        <Form.Item>
          <Space>
            <span className="ant-form-text">Social Media</span>
          </Space>
        </Form.Item>

        <Form.List
          name="socials"
          rules={[
            {
              validator: async (_, links) => {
                if (!links || links.length < 1) {
                  return Promise.reject(new Error("At least 1 Social Media"));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Space
                  key={field.key + index}
                  className="social-media-items"
                  align="center"
                >
                  <Form.Item
                    {...field}
                    label="Name"
                    key={"name" + index}
                    name={[field.name, "name"]}
                    // requiredMark={'optional'}
                    rules={[
                      { required: true, message: "Missing Social Media" },
                    ]}
                  >
                    <Select placeholder="Select a social media" allowClear>
                      {SOCIALS.map((social, index) => {
                        return (
                          <Option key={index} value={social.key}>
                            {social.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label="Url (Must begin with http:// or https://)"
                    key={"url" + index}
                    name={[field.name, "url"]}
                    // requiredMark={'optional'}
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: true,
                        // type: "url",
                        message: "Invalid url",
                        pattern: new RegExp(
                          "(https://www.|http://www.|https://|http://)?([a-zA-Z0-9]{2,}.[a-zA-Z]{2,})+"
                        ),
                      },
                    ]}
                  >
                    <Input placeholder="" />
                  </Form.Item>

                  {fields.length > 1 ? (
                    <DeleteOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="text"
                  onClick={() => add()}
                  style={{ marginLeft: "auto" }}
                  icon={<PlusOutlined />}
                >
                  Add Another Social
                </Button>
              </Form.Item>
              <div>
                <Form.ErrorList errors={errors} />
              </div>
            </>
          )}
        </Form.List>

        <Form.Item
          label="Intro video"
          name="introVideo"
          rules={[
            {
              required: false,
              message: "Please input your project youtube intro video!",
            },
          ]}
        >
          <Input placeholder="e.g https://www.youtube.com/watch?v=RQlp-p_Qcsw" />
        </Form.Item>

        {introVideo && (
          <div className="flex mb-[20px]  w-1/2 justify-between border-none">
            <iframe
              width="560"
              height="315"
              src={embededUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        )}

        <Divider />

        <Form.Item>
          <Button
            size="large"
            className="next-btn"
            type="primary"
            htmlType="submit"
          >
            Next - Fundraiser Settings
          </Button>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};
