// import Link from "next/link";

// import { SeedToken } from "antd/es/theme/interface";

import { LikeOutlined } from "@ant-design/icons";
// import Link from "next/link";
import { ColumnsType } from "antd/es/table";
import { ProjectNamespace } from "../../../_shared/namespaces/project";
import { Dispatch, SetStateAction, useCallback, useContext, useState } from "react";
import { EMPTY_ADDRESS, TOKEN_SYMBOL, computeCurrentPhaseEndDate, moneyFormat } from "../../../_shared";
import { toEther } from "../../../web3/libs/utilities";
import {
  PSAC_TOKEN_ADDRESS,
  SAC_ADMIN_ADDRESS,
  sacToken,
} from "../../../_shared";
import { WalletConnectorContext } from "../../../contexts";
import Web3 from "web3";
import { sacAdminAbi } from "../../../_shared/ABIcode/sac-admin";
import { useNavigate } from "react-router-dom";


import {
  Button,
  Avatar,
  Popconfirm,
  Select,
  Progress,
  Tag,
  theme,
  Typography,
  Spin,
} from "antd";
// import Link from "next/link";
import { Container, CustomTable } from "./index.styled";
import { useProject } from "../../../hooks";

const { useToken } = theme;
const { Text } = Typography;

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

interface SacTableSectionProps {
  votingCost: string;

  projects: ProjectNamespace.Project[];
  tableFilter: "trending" | "recentlyListed" | "upcoming" | "completed";

  setTableOrder: Dispatch<SetStateAction<"asc" | "desc">>;
  setTableFilter: Dispatch<
    SetStateAction<"trending" | "recentlyListed" | "upcoming" | "completed">
  >;
  setToggleTable: Dispatch<SetStateAction<boolean>>
}

export const SacTableSection = (props: SacTableSectionProps) => {
  const { projects, setTableOrder, tableFilter, setTableFilter, votingCost, setToggleTable } =
    props;
  const [loadingMap, setLoadingMap] = useState<Record<string, boolean>>({});
  const { token } = useToken();
  const { provider, address } = useContext(WalletConnectorContext);
  const KEY = `@@SacTableSection`;
  const navigate = useNavigate();
  const { handleGet } = useProject({
    key: KEY,
  });
  const loadUtils = useCallback(
    (id: string) => {
      handleGet(`${id}/chainData/${address??EMPTY_ADDRESS}`, {
        onFinish: (data: any) => {
          
        },
      });
    },
    [address, handleGet]
  );
  const handleConfirm = async (project_id: string) => {
    setLoadingMap((old) => ({ ...old, [project_id]: true }));
    try {
      const chAllow = await checkContractAllowanceEnough();
      console.log({ chAllow });
      if (!chAllow) {
        const upAllow = await updateAllowance();
        console.log({ upAllow });
      }
      const vote = await performVote(project_id);
      console.log({ vote });
    } catch (error) {
      console.log({ hanldeUnlockErr: error });
      setLoadingMap((old) => ({ ...old, [project_id]: false }));
    }
    setLoadingMap((old) => ({ ...old, [project_id]: false }));
  };

  const columns: ColumnsType<ProjectNamespace.Project> = [
    {
      title: "Project",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <>
          <Button onClick={()=>navigate(`/sac/${record.id}`)} type="link"><div className="flex items-center justify-start gap-2">
            <Avatar src={record.logo} size={50} />
            <Text className="font-bold whitespace-nowrap">{record.title}</Text>
          </div>
          </Button>
        </>
      ),
    },
    {
      title: "Votes",
      dataIndex: "age",
      key: "age",
      render: (_, record) => (
        <>
          <Popconfirm
            title={`Voting would cost ${moneyFormat(
              toEther(`${votingCost}`, 2),
              2
            )} ${TOKEN_SYMBOL} `}
            description='Click "Yes" to conitue'
            onConfirm={ async () => {
              if (loadingMap[`${record.id}`]) return;
              await handleConfirm(`${record.id}`);
              setTimeout(
                function() {
                  loadUtils(record?.id+"");
                  setToggleTable(old=>!old)
                }, 5000);
              
            }}
            // onCancel={cancel}
            okText="Yes"
            // cancelText="No"
          >
            <Tag className={`p-[8px] bg-transparent border border-white border-opacity-25  cursor-pointer hover:border-2 hover:border-white`}>
              {loadingMap[`${record.id}`] ? (
                <Spin />
              ) : (
                <LikeOutlined className="text-lg" />
              )}
              <Text className=" number-font ">
                {" "}
                {record.votes ?? 0}
              </Text>
            </Tag>
          </Popconfirm>
        </>
      ),
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   render: (_, record) => (
    //     <>
    //       <Text className="">
    //         {record.tagline ?? record.description}
    //       </Text>
    //     </>
    //   ),
    // },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },

    {
      title: "Product Status",
      dataIndex: "productStage",
      key: "productStage",
    },
    {
      title: "Amount raised",
      dataIndex: "address",
      key: "address",
      render: (_, record) => {
        let cmp = <></>;
        try {
          cmp = (
            <Text className="number-font">
              {" "}
              $
              {moneyFormat(
                record.totalSacrificedValueNumber,
                2
              )}
            </Text>
          );
        } catch (error) {}
        return cmp;
      },
    },
    {
      title: "No. of contributors",
      dataIndex: "contributors",
      key: "contributors",
      render: (_, record) => (
        <>
          <Text className="number-font">{record.contributors ?? 0}</Text>
        </>
      ),
    },
    {
      title: "Progress",
      key: "tags",
      dataIndex: "tags",
      render: (_, { phases, startDayTime }) => {
        phases = phases ?? [];
        const currentPhase = computeCurrentPhaseEndDate(
          startDayTime ?? 0,
          phases
        );
        const index = currentPhase[1] + 1;
        const percent = (index / phases.length) * 100;
        return (
          <>
            <Progress
              className="w-full"
              steps={phases.length}
              percent={percent}
              showInfo={false}
            />
          </>
        );
      },
    },
  ];

  const updateAllowance: () => Promise<boolean> = async () => {
    if (!votingCost) return false;
    console.log({ SAC_ADMIN_ADDRESS, address });

    const web3 = new Web3(provider);

    const psacContract = new web3.eth.Contract(
      sacToken as any,
      PSAC_TOKEN_ADDRESS
    );

    // allowance // call
    try {
      const updateAllowance: any = await psacContract.methods["approve"](
        SAC_ADMIN_ADDRESS,
        votingCost
      ).send({
        from: address,
      });
      console.log({ updateAllowance });
      return true;
    } catch (error) {
      return false;
    }
  };

  const checkContractAllowanceEnough: () => Promise<boolean> = async () => {
    const web3 = new Web3(provider);

    const psacContract = new web3.eth.Contract(
      sacToken as any,
      PSAC_TOKEN_ADDRESS
    );

    try {
      const amountInWei: any = await psacContract.methods["allowance"](
        address,
        SAC_ADMIN_ADDRESS
      ).call();
      console.log({ amountInWei });
      const _n = BigInt(`${votingCost}`);
      return _n < amountInWei;
    } catch (error) {
      console.log({ error });
      return false;
    }
  };

  const performVote: (project_id: string) => Promise<boolean> = async (
    project_id
  ) => {
    const web3 = new Web3(provider);
    const contract = new web3.eth.Contract(
      sacAdminAbi as any,
      SAC_ADMIN_ADDRESS
    );
    try {
      const vote = await contract.methods["vote"](project_id).send({
        from: address,
      });
      console.log({ vote });
      return true;
    } catch (error) {
      console.log({ error });
      return false;
    }
  };

  return (
    <Container token={token}>
      <div className="flex justify-between gap-[20px] flex-wrap ">
        <div className="flex gap-[20px]">
          <Button
            onClick={() => {
              setTableFilter("trending");
            }}
            type={tableFilter == "trending" ? "primary" : "default"}
            style={{
              color: tableFilter == "trending" ? token.colorText : "#7c7c7c",
            }}
          >
            Trending
          </Button>
          <Button
            onClick={() => {
              setTableFilter("recentlyListed");
            }}
            type={tableFilter == "recentlyListed" ? "primary" : "default"}
            style={{
              color:
                tableFilter == "recentlyListed" ? token.colorText : "#7c7c7c",
            }}
          >
            Recently Listed
          </Button>
          <Button
            onClick={() => {
              setTableFilter("completed");
            }}
            type={tableFilter == "completed" ? "primary" : "default"}
            style={{
              color: tableFilter == "completed" ? token.colorText : "#7c7c7c",
            }}
          >
            Completed
          </Button>
        </div>
        <Select
          defaultValue="desc"
          onChange={(value: any) => {
            setTableOrder(value);
          }}
          options={[
            { value: "desc", label: "Volume high to low" },
            { value: "asc", label: "Volume low to high" },
          ]}
        />
      </div>
      <CustomTable
        columns={columns as any}
        dataSource={projects}
        pagination={false}
        // pagination={{
        //   total: 85,
        //   showTotal: (total, range) => (
        //     <Text>
        //       Showing {range[0]}-{range[1]} of {total} list
        //     </Text>
        //   ),
        //   defaultPageSize: 20,
        //   defaultCurrent: 1,
        //   showSizeChanger: false,
        //   responsive: true,
        // }}
      />
    </Container>
  );
};
