import { CheckCircleOutlined } from "@ant-design/icons";
import { Descriptions, List, Table, theme, Typography } from "antd";
// import Link from "next/link";
import { Container } from "./index.styled";
import { ProjectNamespace } from "../../../_shared/namespaces/project";
import { toEther } from "../../../web3/libs/utilities";
import { moneyFormat, TOKEN_SYMBOL } from "../../../_shared";
import { ColumnsType } from "antd/es/table";
const { useToken } = theme;
const { Text } = Typography;

const data00 = [
  "Create a fundraiser",
  "Time Reward (2 Tiers)",

  "Volume Reward (2 Tiers)",

  "Referral Reward (Fixed)",

  "Token Distribution",
];

interface FeatureSectionProps {
  features?: ProjectNamespace.FeatureData;
}

export const FeatureSection = (props: FeatureSectionProps) => {
  const { features } = props;

  const { token } = useToken();

  const columns: ColumnsType<any> = [
    {
      title: "Features",
      key: "agvaluee",
      render: (_, record) => (
        <>
          <Text className="font-extrabold text-lg number-font">
            {record.label}
          </Text>
        </>
      ),
    },

    {
      title: `Burn ${TOKEN_SYMBOL} to unlock`,
      key: "agvaluee",
      render: (_, record) => (
        <>
          <Text className="font-extrabold text-lg number-font">
            {record.value}
          </Text>
        </>
      ),
    },
  ];

  return (
    <Container token={token} id="pricing">
      <Text className="text-xl lg:text-6xl font-semibold whitespace-nowrap text-center mb-[80px]">
        Features
      </Text>
      <div className="xl:flex py-[60px] lg:gap-x-16 rounded-xl px-5 lg:px-[90px] justify-between bg-gray-sec-500">
        <div className="w-1/3 pt-[5%] hidden xl:block">
          <div className="flex flex-col justify-between gap-[50px] overflow-auto">
            <Text className="text-5xl font-semibold leading-[118%]">
              Burn {TOKEN_SYMBOL} to unlock extra features
            </Text>
            <Text className="text-base font-normal leading-[167%]">
              Create and launch your fundraiser from start to finish
              using our basic features. And If you want more flexibility and
              customisation, you can burn {TOKEN_SYMBOL} to unlock extras.
            </Text>
          </div>
        </div>
        <div className="w-full xl:w-2/3">
          <Table
            dataSource={[
              ...(data00.map((item) => ({
                label: item,
                value: "FREE",
              })) ?? []),
              ...(features?.features.map((feature) => {
                return {
                  label: feature.label,
                  value: `${moneyFormat(toEther(feature.price, 2), 2)} ${TOKEN_SYMBOL}`,
                };
              }) ?? []),
            ]}
            columns={columns}
            pagination={false}
          />
        </div>
      </div>
      
    </Container>
  );
};
