import { ThemeContext } from "../../../contexts";
import { MenuOutlined } from "@ant-design/icons";
import { Typography, Button, Switch, Row, Col } from "antd";
import { useContext } from "react";
import { StyledHeader} from "./index.styled";
import { APP_NAME } from "../../../_shared";
const { Text } = Typography;


interface LandingMainHeaderProps {
    text?: string;
}
export const LandingMainHeader = (props: LandingMainHeaderProps) => {
    const { } = props;
    const {setThemeType, themeType} = useContext(ThemeContext);
    
    // const {connect, disconnect, address} = useContext(WalletConnectorContext);
    
    const onChange = (checked: boolean) => {
        if(!setThemeType) return
        if(checked){
            setThemeType('light')
        }else{
            setThemeType('dark')
        }
      };
    return (<StyledHeader>
                <div className="logo-container">
                    <Button className="menu-button" type="text" >
                        <MenuOutlined />
                    </Button>
                    <img src="/pulsesac_logo.svg" alt="...." />
            <Text>{APP_NAME}</Text>
                </div>
                <div className="connect-container">
                    <Row className="link-container" gutter={[20, 2]}>
                        <Col><a href="/#what-is-it">{`Who it’s for`}</a></Col>
                        <Col><a href="/#our-features">Our Features</a></Col>
                        <Col><a href="/#tokenomics" >Tokenomics</a></Col>
                        <Col><a href="/#faqs" >FAQ</a></Col>
                    </Row>
                    {/* {address?
                    <Button onClick={disconnect} type="primary"  shape="round" >Disconnect Wallet</Button>
                    :<Button onClick={connect} className="connect-wallet" shape="round" >Connect Wallet</Button>
                    } */}
                    <Button onClick={()=>window.open("https://testnet.solidbase.com/projects", '_blank' )}  target="_blank" className="btn btn-primary connect-wallet"  >
                        Go To Testnet
                        </Button>
                    <Switch checked={themeType == 'light'} onChange={onChange} />
                </div>
            </StyledHeader>)
};


